import React from "react";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

// Components
import Icon from "./icon";

/* NOTE: This component should be used to create a Gatsby Link, HTML anchor, or HTML button that are styled like a button.
    TYPE: by default the Button type is "link", as most of our buttons are CTAs to internal pages. Available types are:
      "anchor-link" == an internal link to a hash (an element with an ID, e.g. "/path/to/page/#my-element-id" or "/#my-element-id")
        NOTE: "#" is not a valid URL; always include the root slash, e.g. "/#".
      "link" == an internal Gatsby component "Link" to another Gatsby page - compiles to an HTML anchor but with Gatsby pre-fetch page data.
      "anchor" == an HTML anchor to an external page. Must have a valid url for the href.
      "button" == an HTML (form) button without an href, but should have some sort of controller (event handler callback function.)

    ID: a unique id is required on all buttons to support automated testing

    CLASS: by default the button will always have the class "btn" hardcoded, and the class "btn-primary" by default. 
      If you want a different button color, pass the appropriate BS4 button class.
      If you want to add other classes to "btn-primary", you will need to include "btn-primary" in the class value as well since it will overwrite the default value.
      By default buttons have a min-width. If you would like to remove this, add the class no-min-width.
    
    ICON: by default the button will have a "arrow-right" icon floated to the right of the text. 
      If you do not want an icon at all, pass "showIcon: false".
      If you want a different icon, pass an icon object with at least the "name" property (class and lib have appropriate default values).
*/
const Button = (props) => {
  //console.log("Button props: ", props);

  return (
    <div className={props.containerClass}>
      {props.type === "anchor-link" && !props.externalLink && (
        <div
          className={`btn ${props.class}`}
          id={props.id}
          onClick={() => {
            navigate(props.url);
          }}
          role="button"
          tabIndex={props.tabIndex}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              navigate(props.url);
            }
          }}
        >
          {props.showIcon && props.icon && props.icon.position === "left" && <Icon {...props.icon} />}
          <span dangerouslySetInnerHTML={{ __html: props.text }} />
          {props.showIcon && props.icon && (!props.icon.position || props.icon.position === "right") && (
            <Icon {...props.icon} />
          )}
        </div>
      )}
      {props.type === "link" && !props.externalLink && (
        <Link id={props.id} className={`btn ${props.class}`} to={props.url} style={props.style}>
          {props.showIcon && props.icon && props.icon.position === "left" && <Icon {...props.icon} />}
          <span dangerouslySetInnerHTML={{ __html: props.text }} />
          {props.showIcon && props.icon && (!props.icon.position || props.icon.position === "right") && (
            <Icon {...props.icon} />
          )}
        </Link>
      )}
      {props.type === "anchor" || props.externalLink ? (
        <a
          id={props.id}
          className={`btn ${props.class}`}
          href={props.url}
          target={`_${props.target}`}
          rel="noopener noreferrer"
          style={props.style}
          /*  TODO: implement these attributes as needed:
                referrerPolicy: "no-referrer",
                download: "", // filename
                onClick: "" //a callback script to be run when the event fires
                type: "", // media type
                media: "", // optimized for media/device
                lang: "", // language code
                ping: "", // space-speparated list of URLs to ping
                */
        >
          {props.showIcon && props.icon && props.icon.position === "left" && <Icon {...props.icon} />}
          <span dangerouslySetInnerHTML={{ __html: props.text }} />
          {props.showIcon && props.icon && (!props.icon.position || props.icon.position === "right") && (
            <Icon {...props.icon} />
          )}
        </a>
      ) : (
        props.type === "anchor" && (
          <a
            id={props.id}
            className={`btn ${props.class}`}
            href={props.url}
            target={`_${props.target}`}
            style={props.style}
          >
            {props.showIcon && props.icon && props.icon.position === "left" && <Icon {...props.icon} />}
            {props.textSm ? (
              <>
                <span className="d-none d-sm-inline" dangerouslySetInnerHTML={{ __html: props.text }} />
                <span className="d-inline d-sm-none" dangerouslySetInnerHTML={{ __html: props.textXs }} />
              </>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: props.text }} />
            )}
            {props.showIcon && props.icon && (!props.icon.position || props.icon.position === "right") && (
              <Icon {...props.icon} />
            )}
          </a>
        )
      )}
      {(props.type === "button" || props.type === "submit" || props.type === "reset") && (
        // buttons (button, submit, reset)
        <button
          id={props.id}
          className={`btn ${props.class}`}
          type={props.type}
          onClick={() => props.onClick()}
          style={props.style}
          disabled={props.disabled}
          ref={props.buttonRef}
          /*  TODO: implement these attributes as needed:
                {...(props.name && { name=props.name })}
                {...(props.value && { value=props.value })}
                {...(props.disabled && { disabled="disabled" })}
                */
        >
          {props.showIcon && props.icon && props.icon.position === "left" && <Icon {...props.icon} />}
          {props.textSm ? (
            <>
              <span className="d-none d-sm-inline" dangerouslySetInnerHTML={{ __html: props.text }} />
              <span className="d-inline d-sm-none" dangerouslySetInnerHTML={{ __html: props.textXs }} />
            </>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: props.text }} />
          )}
          {props.showIcon && props.icon && (!props.icon.position || props.icon.position === "right") && (
            <Icon {...props.icon} />
          )}
        </button>
      )}
    </div>
  );
};

export default Button;
const defaultCallback = (e) => {
  e.preventDefault();
  console.error("Please implement a onClick handler for your button.");
};

Button.defaultProps = {
  id: 1, // REQUIRED: all buttons should have an id to support automated testing
  buttonRef: null,
  type: "anchor", // anchor-link (to a hash id), link (internal Gatsby Link), anchor (an external link),
  // or button (submit, reset, etc.). NOTE: most of our buttons are CTAs that link to internal pages.
  externalLink: false, // only used for type "anchor"
  containerClass: "",
  class: "btn-primary", // do not include "btn" as it is already hardcoded above
  name: "", // only used for type "button"
  text: "",
  textXs: "",
  value: "", // only used for type "button" if different from text
  disabled: false,
  style: {},
  form: {
    // only for type "submit"
    // id: 1, // which form
    // action: "#", // URL to submit to
    // encType: "text/plain", // form-data encoding
    // method: "POST", // get, post
    // noValidate: false, // true == formnovalidate
    // target: "self" // blank, self, parent, top
  },
  showIcon: true, // uses the icon object (below) by default.
  icon: {
    position: "right",
    lib: "fas",
    name: "arrow-right",
    class: "float-right mt-1 ml-3"
  },
  url: "", // only for type "anchor"
  target: "self", // only used for type "anchor": blank, self, parent, top (NOTE: do not included the leading underscore "_")
  onClick: defaultCallback, // callback script to be run when the event fires
  tabIndex: "0"
};

