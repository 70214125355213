import React from "react";
import { Link } from "gatsby";

//Components
import SimpleLanding from "../../../layouts/simple-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Icon from "../../../components/custom-widgets/icon";
import Button from "../../../components/custom-widgets/button";

const BusinessBankingAccountAgreement = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/customer-service",
      title: "Customer Service"
    },
    {
      id: 2,
      url: "/customer-service/account-details/",
      title: "Account Details"
    },
    {
      id: 3,
      active: true,
      title: "Business Banking Account Agreement"
    }
  ];

  const SEOData = {
    title: "Business Banking Agreement",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const BackToTopLink = ({ id, url }) => {
    return (
      <>
        <Button
          id={"nav-btn-back-to-top-" + id}
          type="link"
          url={url}
          text="Back to Top"
          class="btn-anchor-link d-flex no-min-width px-0 text-left"
          icon={{
            position: "left",
            lib: "fal",
            name: "arrow-up",
            class: "mt-1 mr-2"
          }}
        />
        <hr />
      </>
    );
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Business Banking Account Agreement</h1>
        <p>
          <strong>Personal and Small Business Accounts</strong>
        </p>
        <p>
          <a
            className="text-decoration-none"
            title="WaFd Bank Online and Mobile Banking Personal Accounts Only"
            href="/documents/account-details/wafd-bank-online-and-mobile-banking_personal-accounts-only.pdf"
            target="_blank"
          >
            <Icon name="file-pdf" class="mr-2" />
            Personal Account
          </a>
        </p>
        <p>
          <a
            className="text-decoration-none"
            title="WaFd Bank Small Business Online and Mobile Banking Service Agreement"
            href="/documents/account-details/wafd-bank-small-business-online-and-mobile-banking-service-agreement.pdf"
            target="_blank"
          >
            <Icon name="file-pdf" class="mr-2" />
            Small Business Account
          </a>
        </p>
        <p>
          <strong>
            When you are asked to consent to the terms and conditions of the WaFd Bank Online and Mobile Banking Service
            Agreement you are consenting only to the terms and conditions applicable to your type of accounts (i.e.,
            Personal and/or Small Business).{" "}
          </strong>
        </p>
        <p>
          <strong>
            The following agreement is for WaFd Bank Personal Account Customers. Small Business Banking customers,
            please scroll down for the WaFd Bank Online and Mobile Banking Service Agreement for Small Business
            Customers.
          </strong>
        </p>
        <p className="lead">WaFd Bank Online and Mobile Banking Service Agreement for Personal Account Customers</p>
        <p>
          Welcome to Online and Mobile Banking at WaFd Bank. This Online and Mobile Banking Service Agreement
          ("Agreement") sets out the terms and conditions for your use of WaFd Bank's Online Banking Service ("Online
          Banking Service") and Mobile Banking Service ("Mobile Banking Service") provided to our customers with
          personal accounts established primarily for personal, family, or household purposes. Please read this
          Agreement carefully. A printer friendly version of this Agreement is available on our Website. By using the
          Online Banking Service or the Mobile Banking Service, you agree to the terms and conditions of the Agreement
          as they apply to the service you are using, including any instructions we provide on our Website or on your
          Mobile Device, and any changes in such terms and conditions (after their effective date). If you do not agree
          with these terms and conditions, you may not use the Online Banking Service or the Mobile Banking Service.
        </p>
        <p>
          In this Agreement, the words "we", "us", "our", and "Bank" means WaFd Bank and the words "you" and "your" mean
          an Online Banking Service customer or a Mobile Banking Service Customer. Your use of the Online Banking
          Service and the Mobile Banking Service is also governed by our Personal Deposit Account Agreement and
          Disclosures (if you have a Deposit Account with us),our Website Terms of Use, the Schedule of Consumer Fees
          &amp; Service Charges, and any Loan Account agreement (collectively, the "Account Agreements").The Personal
          Account Agreement and Disclosures, our Website Terms of Use, and the Schedule of Consumer Fees &amp; Service
          Charges are available on our Website and you may also request a printed copy of any of these documents by
          visiting your local branch.
        </p>
        <p id="table-of-contents-personal-account">
          <strong>Table of Contents</strong>
        </p>
        <ol className="mb-5">
          <li>
            <Link to="#pa-definitions">
              <strong>Definitions</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-security">
              <strong>Security, Password and User Responsibility for Online and Mobile Banking Service </strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#pa-security-security-measures">
                  <strong>Security Measures</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-security-password">
                  <strong>Password</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-security-user-responsibility">
                  <strong>User Responsibility for Online and Mobile Services</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#pa-access-requirements">
              <strong>Access Requirements for Online Banking Service</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-online-banking-services">
              <strong>Available Online Banking Services</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-mobile-banking-services">
              <strong>Mobile Banking Services</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#pa-mobile-banking-services-sms-terms-and-conditions">
                  <strong>SMS Terms and Conditions</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-mobile-banking-services-access-requirements">
                  <strong>Access Requirements for the Mobile Banking Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-mobile-banking-services-available-mobile-banking-services">
                  <strong>Available Mobile Banking Services</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-mobile-banking-services-mobile-deposit-service">
                  <strong>Mobile Deposit Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-mobile-banking-services-sms-text-service">
                  <strong>SMS (Text) Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-mobile-banking-services-push-notifications">
                  <strong>Push Notifications</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#pa-conditions-and-limitations">
              <strong>Conditions and Limitations on Online and Mobile Banking Services </strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#pa-conditions-and-limitations-savings-and-money-market-accounts">
                  <strong>Savings and Money Market Accounts</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-conditions-and-limitations-internal-transfers">
                  <strong>Internal Transfers</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#pa-fees">
              <strong>Fees</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-placing">
              <strong>Placing Stop Payment Orders</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-statements">
              <strong>Electronic Statements and Notices</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-using-email">
              <strong>Using E-mail to Send Information and Inquiries to Us</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-third-party-services">
              <strong>Third Party Services</strong>
              <ol className="mt-2">
                <li>
                  <Link to="#pa-third-party-services-moneysync">
                    <strong>MoneySync</strong>
                  </Link>
                </li>
                <li>
                  <Link to="#pa-third-party-services-external-transfer-and-popmoney">
                    <strong>
                      External Transfer and Popmoney<sup>&reg;</sup>
                    </strong>
                  </Link>
                </li>
                <li>
                  <Link to="#pa-third-party-services-checkfree-web-bill-payment-services">
                    <strong>CheckFree Web Bill Payment Services (Bill Pay)</strong>
                  </Link>
                </li>
              </ol>
            </Link>
          </li>
          <li>
            <Link to="#pa-suspension-or-cancellation">
              <strong> Online and Mobile Banking Suspension or Cancellation by Us</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-privacy">
              <strong> Privacy</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-limitations-on-our-responsibilities">
              <strong>Limitations on Our Responsibilities</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-indemnification">
              <strong>Indemnification</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-notices-and-communication">
              <strong>Notices and Communication</strong>
            </Link>

            <ol className="mt-2">
              <li>
                <Link to="#pa-notices-and-communication-generally">
                  <strong>Generally</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-notices-and-communication-electronic">
                  <strong>Electronic Delivery and Communication</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-notices-and-communication-returned-information">
                  <strong>Returned, Unclaimed Account-Related Information</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-notices-and-communication-address-changes">
                  <strong>Address Changes</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-notices-and-communication-implementation-of-notices">
                  <strong>Implementation of Notices</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-notices-and-communication-consumer-disclosures">
                  <strong>Consumer Disclosures</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#pa-eft-provisions">
              <strong>Electronic Fund Transfer (EFT) Provisions</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-availability-of-services">
              <strong>Availability of Services; Business Days</strong>
            </Link>
          </li>
          <li>
            <Link to="#pa-general-provisions">
              <strong>General Provisions</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#pa-general-provisions-amendments-to-this-agreement">
                  <strong>Amendments to this Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-termination-to-this-agreement">
                  <strong>Termination of this Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-governing-law-and-attorney-fees-">
                  <strong>Governing Law and Attorney Fees</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-proprietary-rights">
                  <strong>Proprietary Rights</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-entire-agreement">
                  <strong>Entire Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-waiver">
                  <strong>Waiver</strong>
                </Link>
              </li>
              <li>
                <Link to="#pa-general-provisions-jury-trial-waiver">
                  <strong>Jury Trial Waiver</strong>
                </Link>
              </li>
            </ol>
          </li>
        </ol>

        <div id="pa-definitions">
          <p className="lead">1. Definitions</p>
          <p>"Business Day" means Monday through Friday, excluding federal banking holidays.</p>
          <p>
            "Computer" means a personal computer (PC) or other desktop Internet-enabled system, including a laptop
            personal computer and excludes a Mobile Device.
          </p>
          <p>
            "Consumer Fee Schedule" means the Schedule of Consumer Fees &amp; Service Charges, which is available on the
            Website and in printed form upon request at your local branch.
          </p>
          <p>
            "Deposit Account" means a checking, savings, NOW, money market, or certificate of deposit account that you
            have established with us primarily for personal, family or household purposes.
          </p>
          <p>
            "EFT" means electronic fund transfers that debit or credit a consumer account and are subject to the Federal
            Reserve Board's Regulation E.
          </p>
          <p>
            "Eligible" means an account that is accessible through the Online Banking Service or Mobile Banking Service.
          </p>
          <p>
            "Eligible Accounts" means Deposit Accounts and Loan Accounts that are accessible through the Online and
            Mobile Service.
          </p>
          <p>
            "Loan Account" means a loan account you may have with us that was established primarily for personal, family
            or household purposes.
          </p>
          <p>
            "Mobile Banking Service" means any activity via our Website and any published WaFd Bank application using
            the Mobile Device you have registered with us.
          </p>
          <p>
            "Mobile Device" means a supported mobile device including a mobile phone or other mobile device that is
            web-enabled or capable of sending and receiving text messages.
          </p>
          <p>
            "Online Banking Service" means any activity on our Website using a personal computer or other
            internet-enabled system, but excluding any service accessed through a Mobile Device.
          </p>
          <p>
            "Online and Mobile Service" means the Online Banking Service and Mobile Banking Service described in this
            Agreement.
          </p>
          <p>
            "Online Financial Services" means each of the products and services you may access or enroll in through the
            Online and Mobile Service, including services offered by third parties.
          </p>
          <p>"Owner" means each person who is an owner of an Eligible Account as indicated in our records.</p>
          <p>
            "Personal Deposit Agreement" means the Personal Deposit Account Agreement and Disclosures, which are
            available on the Website and in printed form upon request at your local branch.
          </p>
          <p>"SMS" means Short Message Service, which is the text message service of your Mobile Device.</p>
          <p>"Transaction Processing Day" means a Business Day or any other day that Bank processes transactions.</p>
          <p>
            "Website" means WaFd Bank's Online Banking Service website and other websites and web pages maintained by us
            and accessible through www.wafdbank.com.
          </p>
          <BackToTopLink id="pa-01" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-security">
          <p className="lead">2. Security, Password and User Responsibility for Online and Mobile Banking Services</p>
          <ol>
            <li id="pa-security-security-measures">
              <strong>Security Measures</strong>
              <p>
                You can use your user name and password, along with a Computer or Mobile Device, to perform certain
                transactions described below. For security, the Online and Mobile Service will not allow access if
                anyone attempts to use a wrong password 5 times or more consecutively. We use additional log-in security
                that incorporates multiple factors to authenticate you as a customer. Additional information regarding
                each Online and Mobile Service user&rsquo;s access pattern will be collected and validated
                automatically. A unique electronic signature will be created for each user based on the user&rsquo;s
                specific access pattern; additional authentication may occur automatically when there is a deviation
                from this unique signature.
              </p>
              You agree that we may place session cookies and other identifiers on the Computers, Mobile Devices and
              other devices you use to access the Online and Mobile Service. Session cookies help us confirm your
              identity and help us keep your transactions secure when you use the Online and Mobile Service. We also use
              cookies for various purposes such as maintaining continuity during an online session, and optimizing the
              performance of our Website.
            </li>
            <li id="pa-security-password">
              <strong>
                Password
                <br />
              </strong>
              Your password can be changed within the Online and Mobile Service. Your password must meet the standards
              we establish from time to time. These standards will be available for your reference whenever you create
              or change a password. We may require you to change or update your password at any time.
            </li>
            <li id="pa-security-user-responsibility">
              <strong>
                User Responsibility for Online and Mobile Services
                <br />
              </strong>
              <p>
                You should never provide your user name or password information to a third party. If you do so, you are
                authorizing that party to conduct transactions on your Eligible Accounts. You are also responsible for
                keeping your username and password confidential and for ensuring that you have logged out of the Online
                Banking Service or Mobile Banking Service when your session is finished. Therefore, you are responsible
                for any transactions, including transfers from or between your Eligible Accounts, resulting from you
                furnishing your user name, password and enhanced log-in security information to a third party. The Bank
                will not be liable and will not reimburse you for any losses that may occur as a result of this{" "}
                <em>authorized</em> use of your user name, password and enhanced log-in security.
              </p>
              <p>If you believe that:</p>
              <ul>
                <li>your password may have been lost or stolen,</li>
                <li>
                  a device you use to access the Online and Mobile Service has been lost, stolen or compromised, or
                </li>
                <li>
                  someone has viewed, downloaded, or deleted electronic records from your account or Online and Mobile
                  Service without your permission,
                </li>
              </ul>
              <p>
                Or if you suspect any fraudulent activity on your accounts, you agree to immediately notify WaFd Bank
                Customer Service by:
              </p>
            </li>
          </ol>
          <p>
            Your liability for unauthorized transactions is dependent upon how promptly you notify us. Please refer to
            the Personal Deposit Agreement for additional information regarding your obligation to notify us and your
            liability for unauthorized transactions on your accounts as provided for under Regulation E.
          </p>
          <BackToTopLink id="pa-02" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-access-requirements">
          <p className="lead">3. Access Requirements for Online Banking Service</p>
          <p>
            In order to use the Bank's Online Banking Service, you will need a Computer and, any software and required
            equipment that can support the list of approved web browsers. The Website is best viewed using the most
            current version of Internet Explorer, Chrome, or Firefox. If you choose to use another browser to access the
            Website, we assume no responsibility for the resulting layout or content. You will also need a functioning
            email address. You are responsible for the installation, maintenance and operation of your Computer and all
            related charges. You are responsible for installing and maintaining appropriate virus protection on your
            Computer. We are not responsible for any errors or failures caused by any malfunction of your Computer, or
            your use of the Online Banking Service. We are not responsible for any viruses, spyware, malware, worms or
            related problems that may be associated with your Computer. We are also not responsible for any losses or
            delays in transmission of information that you provide to us or otherwise arising out of or incurred in
            connection with the use of any internet or other service provider providing your connection to the internet
            or any browser software. You are also responsible for all telephone, mobile remote access and other similar
            charges incurred in connecting to the Online Banking Service and for charges by any internet service
            providing connection to the internet.
          </p>
          <BackToTopLink id="pa-03" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-online-banking-services">
          <p className="lead">4. Available Online Banking Services</p>
          <p>Online Banking Services allow you to perform the following actions:</p>
          <ul>
            <li>Review a summary of your Eligible Deposit and Loan Accounts.</li>
            <li>
              Check the activity of your Eligible Deposit and Loan Accounts, including determining which checks have
              cleared and viewing images of cleared checks, for a period of 13 months.
            </li>
            <li>Advance funds from Eligible Loan Accounts to Eligible Deposit Accounts.</li>
            <li>Submit payments to Eligible Loan Accounts</li>
            <li>
              Transfer funds between Eligible Deposit Accounts and permitted accounts at other financial institutions
              (using External Transfer and Popmoney<sup>&reg;</sup>)
            </li>
            <li>Change passwords, login name, or e-mail addresses</li>
            <li>Pay bills using CheckFree Bill Payment Services.</li>
            <li>Stop payment on checks that have not been paid.</li>
            <li>View 18 months of electronic statements if you have enrolled in E-statements.</li>
            <li>Send and receive electronic mail to and from us.</li>
          </ul>
          <p>
            External Transfer, Popmoney<sup>&reg;</sup> and CheckFree Bill Payment are described in the section entitled
            Third Party Services.
          </p>
          <p>Not all services specified above are available for certificate of deposit (CD) Deposit Accounts.</p>
          <p>
            We may periodically make available new internet services or products. You agree that if you use or acquire
            any such services or products after they become available, you will be bound by all applicable terms, as
            well as by this Agreement.
          </p>
          <p>Not all services specified above are available through Mobile Banking Services.</p>
          <BackToTopLink id="pa-04" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-mobile-banking-services">
          <p className="lead">5. Mobile Banking Services</p>
          <p>
            WaFd Bank offers banking services on your Mobile Device through Mobiliti. Our Mobile Banking Service is an
            Internet-based platform you access via a URL from your Mobile Device's browser or by downloading an App from
            either the Apple App Store or Google Play. Internet access from your Mobile Device is required to enroll in
            some Mobile Banking Services.
          </p>
          <ol>
            <li id="pa-mobile-banking-services-sms-terms-and-conditions">
              <strong>SMS Terms and Conditions</strong>
              <p>
                <strong>Message Frequency Based on Account Settings</strong>. For help, text "HELP" to 66273. To cancel,
                text "STOP" to 66273 at any time. In case of questions please contact customer service at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-01">
                  800-324-9375
                </a>{" "}
                or visit our Website, www.wafdbank.com.
              </p>
              <p>
                <strong>Program.</strong> We offer our customers mobile access to their Bank account information (e.g.,
                for checking balances and last transactions) over SMS, as well as the option to set up alerts for their
                accounts (e.g., low balance alerts). Enrollment requires identification of the customer's banking
                relationship as well as providing a mobile phone number. The mobile phone number's verification is done
                by the customer receiving an SMS message with a verification code which the user will have to enter on
                the Website. Additionally, customers may select the type of alerts and other preferences which will
                determine, together with their account data, the frequency of alerts delivered to the customer. The SMS
                program continues until a customer opts out. Your mobile carrier's text message, data rates, and mobile
                phone usage charges may apply. Customers may opt out of this SMS program at any time.
              </p>
              <p>
                Questions: You can contact us at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-02">
                  800-324-9375
                </a>
                , or send a text message with the word "HELP" to this number: 66273. We can answer any questions you
                have about the SMS program.
              </p>
              <p>
                <strong>To Stop the SMS Program</strong>. To stop the messages from coming to your phone, you can opt
                out of the SMS program via SMS. Just send a text that says "STOP" to this number: 66273. You'll receive
                a one-time opt-out confirmation text message. After that, you will not receive any future messages.
              </p>
              <p>
                <strong>Terms and Conditions</strong>. By participating in Mobile Banking Services, you are agreeing to
                these SMS Terms and Conditions.
              </p>
              Our participating carriers include (but are not limited to): AT&amp;T, SprintPCS, T-Mobile<sup>&reg;</sup>
              ,U.S. Cellular<sup>&reg;</sup>, Verizon Wireless, and MetroPCS.
            </li>
            <li id="pa-mobile-banking-services-access-requirements">
              <strong>Access Requirements for the Mobile Banking Service</strong>
              <br />
              To register a Mobile Device for any of our Mobile Services, you must be an authorized user for the Mobile
              Device registered and you agree to provide us with true, accurate, current and complete information during
              the enrollment and registration process. You are responsible for the installation, maintenance and
              operation of your Mobile Device. You are responsible for installing and maintaining appropriate virus
              protection on your Mobile Device. We are not responsible for any errors or failures caused by any
              malfunction of your Mobile Device, or your use of the Mobile Banking Service. We are not responsible for
              any viruses, spyware, malware, worms or related problems that may be associated with your Mobile Device.
              We are also not responsible for any losses or delays in transmission of information that you provide to us
              or otherwise arising out of or incurred in connection with the use of any internet or other service
              provider providing your connection to the internet or any browser software. You are responsible for any
              fees or other charges that your wireless carrier may charge for any related data or message services,
              including without limitation, for SMS (short message service).
            </li>
            <li id="pa-mobile-banking-services-available-mobile-banking-services">
              <strong>
                Available Mobile Banking Services
                <br />
              </strong>
              Mobile Banking Services allow you to perform the following actions:
              <br />
              <br />
              <ul>
                <li>Review a summary of your Eligible Deposit and Loan Accounts.</li>
                <li>
                  Check the activity of your Eligible Deposit and Loan Accounts, including determining which checks have
                  cleared, for a period of 13 months.
                </li>
                <li>Advance funds from Eligible Loan Accounts to Eligible Deposit Accounts.</li>
                <li>Submit payments to Eligible Loan Accounts.</li>
                <li>Transfer funds between Eligible Deposit Accounts.</li>
                <li>
                  Transfer funds to other persons (using Popmoney<sup>&reg;</sup>).
                </li>
                <li>Change passwords.</li>
                <li>Pay bills using CheckFree Bill Payment Services.</li>
                <li>Deposit checks through our Mobile Deposit Service.</li>
                <li>Initiate transfers via SMS banking.</li>
                <li>Push Notifications (using the Mobile Banking App) and SMS alerts.</li>
              </ul>
            </li>
          </ol>
          <p>
            Popmoney<sup>&reg;</sup> and CheckFree Bill Payment are described in the section entitled Third Party
            Services. Transfers to or from an account at another financial institution using External Transfer are not
            currently available using the Mobile Banking Service. The services specified above are not available for
            certificate of deposit (CD) Deposit Accounts and not all services are available for Loan Accounts. Not all
            services available in the Mobile Banking Service are available in our Online Banking Service.
          </p>
          <ol start={4}>
            <li id="pa-mobile-banking-services-mobile-deposit-service">
              <strong>
                Mobile Deposit Service
                <br />
              </strong>
              The Mobile Deposit Service is a remote deposit capture service (referred to as a "Mobile Deposit Service")
              that allows you to use a camera-enabled Mobile Device to deposit certain checks ("Eligible Checks" as
              described below) to your Eligible Accounts by scanning a check and electronically delivering the digital
              image of the check and associated deposit information to us or our designated processor.
            </li>
          </ol>
          <p>
            Eligible Checks. "Eligible Checks" means checks as defined by Regulation CC and does not include Ineligible
            Items (defined below).
          </p>
          <p>
            Ineligible Items. You may not use the service to deposit any Ineligible Items. "Ineligible Items" include
            any item that : (a) is payable to "Cash" or any indefinite payee or to a person other than you, even if
            endorsed to you; (b) lacks the original signature of the drawer; (c) was created from an electronic image;
            (d) is irregular in any way, as determined by us in our sole discretion; (e) was returned unpaid; (f) is not
            dated or is postdated, or is more than six months old; (g) was drawn on a foreign bank or any entity that is
            not a bank, or is payable in a foreign currency; (h) is a traveler's check or registered government warrant
            or bond; or (i) is not a check under Regulation CC.
          </p>
          <p>
            Image Quality. The image of an item transmitted to us using the Mobile Deposit Service must be legible. The
            image quality of the check or item must comply with the requirements established from time to time by the
            American National Standard Institute, the Board of Governors of the Federal Reserve Board, or any other
            regulatory agency, clearing house or association.
          </p>
          <p>
            Endorsement. You agree to endorse properly each check you deposit using the Mobile Deposit Service and to
            conspicuously and indelibly mark "For Deposit Only at WaFd Bank' beneath your endorsement.
          </p>
          <p>
            Collection. The manner in which the checks you deposit are cleared, presented for payment, and collected
            from makers of the checks and the financial institution upon which they are drawn is in our sole discretion.
          </p>
          <p>
            Availability of Funds; Right to Reject. We reserve the right to place a hold on or to reject any item(s)
            deposited through the Mobile Deposit Service. Please see the Personal Deposit Agreement for information
            regarding availability of funds.
          </p>
          <p>
            Storage and Disposal of Original Checks and Items; Retention of Copies.
            <br />
            Checks Submitted to Us. With respect to any original check for which a check image has been transmitted to
            us via the Mobile Deposit Service, you agree to safely store the check in such a manner that others cannot
            gain access to it until such time as you receive confirmation that your check image has been accepted for
            deposit.
          </p>
          <p>
            Checks Accepted for Deposit. You will receive an electronic confirmation that the check image you
            transmitted to us via the Mobile Deposit Service has been accepted for deposit (or alternatively, an
            adjustment notice setting forth the reason it could not be deposited through the Mobile Deposit Service).
          </p>
          <p>
            Disposal of Transmitted Checks. You agree to safeguard and keep the original check for 14 Business Days
            after you have transmitted the check. After that period, once you have verified that the check has been
            credited to your account, you agree to indelibly mark the check as "VOID" or to dispose of it in a manner
            that will prevent anyone from presenting it for deposit. If we determine that we require the original paper
            check, you will promptly provide any check you have not destroyed to us to aid us in the clearing and
            collection process, to resolve claims by third parties with respect to any Item, or for our audit purposes.
          </p>
          <p>
            Deposit Limits. We have no obligation to accept for deposit any check you transmit using the Mobile Deposit
            Service: (a) if more than one check is included in the transmission; (b) if you have already transmitted
            five checks using the Mobile Deposit Service on that Business Day; (c) that is in an amount greater than
            $5,000; (d) if the amount of the check would, when added to the amount of checks you already transmitted for
            deposit as of that Business Day, would exceed $10,000 or (e) if deposit of that check would cause the sum of
            the amounts of the checks you transmitted for deposit using the Mobile Deposit Service within the previous
            30 days period to exceed $50,000. We reserve the right to modify these limits at any time and will provide
            you with any notice required by applicable law or regulation.
            <br />
            <br />
            You may deposit checks using your Mobile Device Monday through Friday. Accepted deposits made prior to 3 PM
            Pacific Time on a Business Day will be credited by 6 PM Pacific Time the same Business Day. Accepted
            deposits made after 3 PM Pacific Time or on a day that is not a Business Day will be credited the following
            Business Day.
          </p>
          <ol start={5}>
            <li id="pa-mobile-banking-services-sms-text-service">
              <strong>
                SMS (Text) Service
                <br />
              </strong>
              The SMS (Text) service allows you to access available information regarding your Eligible Accounts via SMS
              text messaging from your Mobile Device.
              <br />
            </li>
            <li id="pa-mobile-banking-services-push-notifications">
              <strong>
                Push Notifications
                <br />
              </strong>
              Push notifications allow you to receive "push" versions of some Mobile Banking Service alerts on Apple iOS
              or Google Android Mobile Devices with the WaFd Bank mobile banking application installed. iOS
              notifications can appear as pop up messages or banners and Android notifications appear as a banner. If
              you initiate this service, you can turn off Push notifications within the WaFd Bank mobile banking
              application or in the settings of your Mobile Device.
            </li>
          </ol>
          <BackToTopLink id="pa-05" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-conditions-and-limitations">
          <p className="lead">6. Conditions and Limitations of Online and Mobile Banking Services.</p>
          <ol>
            <li id="pa-conditions-and-limitations-savings-and-money-market-accounts">
              <strong>Savings and Money Market Accounts</strong>.<br />
              Your ability to transfer funds between and/or make payments from certain Deposit Accounts is limited by
              law. For any Statement Savings Account(s) (including Money Market Accounts), you may make as many
              in-person withdrawals at a teller window or any ATM as you wish. However, federal regulations permit the
              depositor to make no more than a combined total of 6 pre-authorized, automatic, electronic (including
              computer initiated), telephone withdrawals or transfers, or by check, draft, debit card, or similar order
              payable to third parties in any monthly period. We may impose a fee, as disclosed on the{" "}
              <em>Consumer Fee Schedule</em>, for each such withdrawal in excess of 6 that you make in any monthly
              period. These fees will be reflected in your monthly statement. In addition, repeated violations will
              result in the Deposit Account being closed or changed from a savings type account to a transaction
              account.
            </li>
            <li id="pa-conditions-and-limitations-internal-transfers">
              <strong>Internal Transfers</strong>.<br />
              Funds can be transferred the same day or on a future date between your Eligible Accounts. Future-dated and
              recurring transfers are processed each calendar day between 9 PM and 11 PM Pacific Time. Same day
              transfers between Eligible Accounts are processed when received. If the transfer request is submitted
              prior to 11 PM Pacific Time it will be dated the same day. Transfer requests submitted after 11 PM Pacific
              Time will be dated the next Transaction Processing Day.
            </li>
          </ol>
          <BackToTopLink id="pa-06" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-fees">
          <p className="lead">7. Fees</p>
          <p>
            All account fees and charges apply to actions requested or performed through the Online and Mobile Services.
            (See the <em>Consumer Fee Schedule</em> for a full listing of fees). We reserve the right to charge fees for
            any service provided through the Online and Mobile Service after we notify you. Unless otherwise required by
            law, you agree that any fees may be deducted automatically from your account (or any other account you have
            with us) if there are insufficient funds in that account.
          </p>
          <BackToTopLink id="pa-07" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-placing">
          <p className="lead">8. Placing Stop Payment Orders</p>
          <p>
            You can place stop payment orders using our Online Stop Payment service. To place a stop payment on a check
            or a series of checks, sign on to the Online Banking Service and select the "Accounts" menu, then select
            "Stop Payment". All information requested must be provided before we can process a stop payment. The terms
            and conditions governing stop payments are provided in our Personal Deposit Agreement. If you send us an
            online stop payment order, we will send you a stop payment confirmation form. If you wish to cancel the stop
            payment or change any information, follow the directions on the confirmation form. There is a fee for
            placing a stop payment (see the <em>Consumer Fee Schedule</em> for the current fee).
          </p>
          <BackToTopLink id="pa-08" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-statements">
          <p className="lead">9. Electronic Statements and Notices</p>
          <p>
            If you enroll to receive eStatements or disclosures, documentation, and other communications concerning your
            Eligible Accounts ("Notices") electronically, statements or Notices, as applicable, for your Eligible
            Accounts will be available only through Online Banking Services. Your consent to receive eStatements covers
            the account statement for the Eligible Accounts you designate within the Online Banking Service. Your
            consent to receive Notices electronically covers any disclosures, agreements, documentation, and other
            communications from the Bank for the Eligible Accounts you designate within the Online Banking Service. The
            Bank will discontinue mailing such account statements and may discontinue mailing Notices to your address of
            record. You agree to be bound by any and all laws, rules, regulations and official issuances applicable to
            eStatements now existing or which may hereafter be enacted, issued or enforced.
          </p>
          <p>
            We will send you notice via e-mail when a new document is available for review. Notice will be sent shortly
            after the end of your statement period and will provide instructions on how to access your eStatement or
            Notice. This is the only notice you receive of the availability of your eStatement or Notice online. We will
            archive electronic statements and Notices for your Eligible Accounts for a rolling 18 months, regardless of
            when you enroll.
          </p>
          <p>
            Your receipt of e-Statements or Notices does not in any way alter your obligation to promptly review your
            transaction history. You must promptly access/review your e-Statement or Notices and any accompanying items
            and notify us immediately of any error, unauthorized transaction, or any other irregularity. The error
            resolution provisions in the Personal Deposit Agreement set out the timelines for reporting errors contained
            on your account statement.
          </p>
          <p>
            At any time, you may request a paper copy of your account statement or any disclosure. Paper copies may be
            acquired by contacting us by phone at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-03">
              800-324-9375
            </a>
            , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, by requesting
            through the Online Banking Service (for account statements only), or by visiting your local branch. Fees may
            apply to requests for paper copies of your account statement (see the <em>Consumer Fee Schedule</em>). There
            is no fee for printed copies of disclosures.
          </p>
          <BackToTopLink id="pa-09" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-using-email">
          <p className="lead">10. Using Email to Send Information and Inquiries to Us</p>
          <p>
            Normal internet e-mail transmissions may not be secure. The Online Banking Service provides secure messaging
            ("Secure Message") for you to ask questions about your accounts or give comments on the Online Banking
            Service. Secure Message provides the ability to securely communicate sensitive or confidential information.
            Secure Message is accessible after you sign on with your password to a secure session of the Online Banking
            Service. To ensure the security of your account information, we recommend that you use only Secure Message
            when asking specific questions about your accounts or when sharing sensitive or confidential account
            information about your WaFd Bank accounts. You cannot use email to initiate transactions on your accounts.
            For transactions, please use the appropriate functions within the Online Banking Service or call WaFd Bank
            Customer Service at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-04">
              800-324-9375
            </a>{" "}
            or by visiting your local branch.
          </p>
          <BackToTopLink id="pa-10" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-third-party-services">
          <p className="lead">11. Third Party Services</p>
          <p>
            Through our Website we offer access to the following services provided by third parties. These services are
            conducted on the Website of these third parties and those sites may have separate terms and conditions.
            Between you and us, your use of those services is subject to this Agreement.
          </p>
          <ol>
            <li id="pa-third-party-services-moneysync">
              <strong>
                MoneySync
                <br />
              </strong>
              We offer MoneySync,a personal finance tool that allows you to aggregate accounts and manage money, through
              MX Technologies, Inc. Your use of MoneySync is subject to the MX Privacy Policy, which is available on our
              Website and on the MoneySync site. MoneySync is available on the Online Banking Service and the Mobile
              Banking Service.
            </li>
            <li id="pa-third-party-services-external-transfer-and-popmoney">
              <strong>
                External Transfer and Popmoney<sup>&reg;</sup>
                <br />
              </strong>
              We offer external transfers and person to person payments through CashEdge. These services are governed by
              the{" "}
              <em>
                EXTERNAL TRANSFER AND POPMONEY<sup>&reg;</sup> PAYMENTS SERVICES TERMS AND CONDITIONS
              </em>
              , which are available on our Website and on the CashEdge site when you access this service. To use
              External Transfers, you must have a personal profile which has a United States address, a unique United
              States phone number, an active unique email address, a Social Security Number or qualifying Tax
              Identification Number (TIN), and applied for and been given access to our Online and Mobile Service. Your
              Eligible Deposit Account must be in good standing and enabled for ACH transactions and Online Banking
              Service transfers. Fees may apply; please review the current <em>Consumer Fee Schedule</em>. CashEdge, the
              service provider, may also charge fees that are specified in their External Transfer and PopMoney
              <sup>&reg;</sup> Payment Services Terms and Conditions. PopMoney<sup>&reg;</sup> is available on the
              Online Banking Service and the Mobile Banking Service. External Transfer is available only on the Online
              Banking Service.
            </li>
            <li id="pa-third-party-services-checkfree-web-bill-payment-services">
              <strong>
                CheckFree Web Bill Payment Services (Bill Pay)
                <br />
              </strong>
              We offer bill payment services through FiServ CheckFree. Use of Electronic Bill Delivery and Bill Payment
              services ("Bill Payment Services") is governed by the <em>TERMS AND CONDITIONS FOR CHECKFREE WEB</em>,
              which is available on our Website and on the CheckFree site when you access this service. The Bill Payment
              Services allows you to pay bills using our Online Banking Service. To be eligible to use the Bill Payment
              Services you must have a personal profile which has a United States Address, a unique United States phone
              number, an active unique e-mail address, and applied for and been given access to our Online and Mobile
              Service. Using the Bill Payment Services, you can arrange for the payment of current, future, and
              recurring bills from your Eligible Accounts. See the <em>Consumer Fee Schedule</em> for applicable fees.
              CheckFree is available on the Online Banking Service and the Mobile Banking Service.
            </li>
          </ol>
          <BackToTopLink id="pa-11" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-suspension-or-cancellation">
          <p className="lead">12. Online and Mobile Banking Suspension or Cancellation by Us</p>
          <p>
            You agree that at any time and without prior notice we may suspend access to your Online or Mobile Service
            if we suspect fraudulent activity or misuse of the Online or Mobile Services or functionality. We may take
            such action to further protect your accounts prior to notice as the speed of such transactions may not allow
            us timely notification to protect your funds or private information.
          </p>
          <BackToTopLink id="pa-12" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-privacy">
          <p className="lead">13. Privacy</p>
          <p>
            We are committed to protecting your privacy. All information gathered from you in connection with using the
            Online and Mobile Service will be governed by the provisions of the WaFd Bank Privacy Policy as set out in
            our Privacy Notice and our Online Privacy Policy, available on our Website at www.wafdbank.com.
          </p>
          <BackToTopLink id="pa-13" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-limitations-on-our-responsibilities">
          <p className="lead">14. Limitations on Our Responsibilities</p>
          <p>
            We agree to make reasonable efforts to ensure the full performance of the Online and Mobile Service. We will
            be responsible for acting only on those instructions that are actually received and cannot assume
            responsibility for malfunctions in communications facilities not under our control that may affect the
            accuracy or timeliness of messages you send. We are not responsible for any losses or delays in transmission
            of instructions arising out of the use of any Internet service provider, your connection to the Internet,
            your Computer, or caused by any browser software. Except as provided under applicable law, our sole
            responsibility for an error in a transfer will be to correct the error, but in no case will we be liable for
            any indirect, special, incidental or consequential damages. In states that do not allow the exclusion or
            limitation of liability for indirect, special, incidental or consequential damages, our liability is limited
            to the maximum extent permitted by applicable law. We are not responsible for any indirect, special,
            incidental of consequential damages arising in any way out of your use of the Online and Mobile Service.
          </p>
          <p>
            WE MAKE NO EXPRESS OR IMPLIED WARRANTIES CONCERNING THE ONLINE AND MOBILE BANKING SERVICE, INCLUDING BUT NOT
            LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF A
            THIRD PARTY'S PROPRIETARY RIGHTS UNLESS DISCLAIMING SUCH WARRANTIES IS PROHIBITED BY APPLICABLE LAW.
          </p>
          <BackToTopLink id="pa-14" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-indemnification">
          <p className="lead">15. Indemnification</p>
          <p>
            Except to the extent that we are liable under the terms of this Agreement or another agreement governing the
            applicable Eligible Account or Online Financial Service, you agree to indemnify, defend, and hold us, our
            affiliates, officers, directors, employees, consultants, agents, service providers, and licensors harmless
            from any and all third-party claims, liability, damages, and/or costs (including but not limited to
            reasonable attorney's fees) arising from:
          </p>
          <ul>
            <li>
              a third-party claim, action, or allegation of infringement, misuse, or misappropriation based on
              information, data, files, or other materials submitted by you to us;
            </li>
            <li>any fraud, manipulation, or other breach of this Agreement or the Online and Mobile Service;</li>
            <li>your violation of any law or rights of a third party; or</li>
            <li>
              the provision of the Online and Mobile Service or use of the Online and Mobile Service by you or any third
              party.
            </li>
          </ul>
          <p>
            We reserve the right, at our own expense, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any
            available defenses. You will not settle any action or claims on our behalf without our prior written
            consent. This indemnification is provided without regard to whether our claim for indemnification is due to
            the use of the Online and Mobile Services by you or your authorized representative or delegate. Nothing
            herein shall be construed as a waiver of any rights you may have as a consumer under any applicable
            regulation.
          </p>
          <BackToTopLink id="pa-15" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-notices-and-communication">
          <p className="lead">16. Notices and Communication</p>
          <ol>
            <li id="pa-notices-and-communication-generally">
              <strong>Generally</strong>
              <br />
              Except as expressly provided otherwise in this Agreement, we will provide you notices and other
              information regarding your Eligible Accounts, Online Financial Service, or the Online and Mobile Service
              (collectively "Account-Related Information") through mail, electronically, or by other means available.
              This information will be sent to the postal or electronic address ("E-Address") of the Owner of the
              applicable Eligible Account or Online Financial Service as reflected in our records, unless a different
              postal or electronic address has been specified in accordance with procedures we may establish from time
              to time. The E-Address may be an e-mail address, other Internet address, text messaging number, or other
              electronic access address provided to us in accordance with this Agreement Account-Related Information
              that is held for pick-up or posted on our Website will be deemed to be delivered to, and received by, you
              at the time that we make it available for pick-up, display it to you, or send notice in accordance with
              this Agreement that it is posted on our Website, as applicable. The person receiving the notice is
              responsible for providing copies of all Account-Related Information to all joint Owners. We reserve the
              right at all times to communicate all Account-Related Information to you through the U.S. Postal Service
              or overnight courier, at our sole option.
            </li>
            <li id="pa-notices-and-communication-electronic">
              <strong>
                Electronic Delivery and Communication
                <br />
              </strong>
              Unless otherwise required by applicable law, if we are required to provide you with information in
              writing, we may, at our option, send it electronically either:
            </li>
          </ol>
          <blockquote>
            <p>
              At any time, you may request a paper copy of any communication which has been delivered electronically.
              Paper copies may be acquired by contacting us by phone at{" "}
              <a href="tel:800-324-9375" id="c3-tel-link-05">
                800-324-9375
              </a>
              , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by
              visiting your local branch. Fees may apply. There is no fee for printed copies of disclosures.
            </p>
          </blockquote>
          <p>
            You agree that we may from time to time make telephone calls and send text messages to you in order for us
            to:
          </p>
          <p>
            The ways we may call you include using prerecorded/artificial voice messages and/or through the use of an
            automatic telephone dialing system. The ways we may send text messages to you include through the use of an
            automatic telephone dialing system. We may call you and send text messages to your telephone number,
            including mobile/cellular telephone numbers that could result in charges to you. You may opt out of
            receiving text messages by calling the Client Care Center at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-06">
              800-324-9375
            </a>
            , visiting your local branch or following the instructions on the text message.
          </p>
          <ol start={3}>
            <li id="pa-notices-and-communication-returned-information">
              <strong>
                Returned, Unclaimed Account-Related Information
                <br />
              </strong>
              Unless otherwise prohibited by the laws governing your Eligible Account or Online Financial Service, this
              section applies if two or more separate Account-Related Information documents are returned or, in the case
              of electronic notifications, one or more notices are returned as undeliverable. This means we may
              discontinue sending Account-Related Information or electronic notifications to you until you provide a
              valid postal or E-Address to us.
            </li>
          </ol>
          <p>Additionally, we may, at our sole option:</p>
          <p>
            For example, if we are sending your Account-Related Information to you via the U.S. Postal Service, and the
            information is returned to us as undeliverable on two or more occasions, we may attempt to deliver the
            information to your E-Address, instead.
          </p>
          <ol start={4}>
            <li id="pa-notices-and-communication-address-changes">
              <strong>Address Changes</strong>
              <p>
                You agree that you will notify us immediately in the event of a change to your address or E-Address.
                Address changes may be initiated at your request. You may instruct us to change the address or E-Address
                to which we send notices or Account-Related Information concerning your account at any time.
              </p>
              <p>
                You may update your E-Address information by logging in to your Online Banking Service and clicking on
                "Update Profile" in the My Information drop down. Physical address changes must be made through your
                branch or by contacting us by U.S. Mail.
              </p>
              Unless you instruct us otherwise, when you notify us of an address change for a specified account, we may
              change the postal or E-Address, as applicable, for all or some of your other account(s) with us.
            </li>
          </ol>
          <ol start={5}>
            <li id="pa-notices-and-communication-implementation-of-notices">
              <strong>
                Implementation of Notices
                <br />
              </strong>
              Except as provided by applicable law, any notice you send us will not be effective until we actually
              receive it and have a reasonable opportunity to act on it. If there is more than one Owner on your
              Eligible Account, we may send Account-Related Information to any one of them. You assume the risk of loss
              in the mail or otherwise in transit. Any notice or Account-Related Information we send you will be
              effective when mailed, sent electronically or otherwise made available to you.
            </li>
            <li id="pa-notices-and-communication-consumer-disclosures">
              <strong>
                Consumer Disclosures.
                <br />
              </strong>
              We are required to make certain disclosures to you in writing under federal consumer protection laws, and
              you can agree to receive some or all of those disclosures electronically pursuant to electronic
              communications disclosures that we may make from time to time (eDisclosure). We agree to provide
              reasonable notice of changes in eDisclosures to the extent required by applicable law. Unless applicable
              law or regulation specifies a longer period, you agree that 15 days will be reasonable notice and that we
              may provide shorter notice if, in our good faith discretion, such is advisable to prevent harm or to
              protect us or you.
            </li>
          </ol>
          <BackToTopLink id="pa-16" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-eft-provisions">
          <p className="lead">17. Electronic Fund Transfer (EFT) Provisions</p>
          <p>
            The terms and conditions applicable to electronic funds transfers (EFTs), including your liability for
            unauthorized EFTs and resolutions or errors or questions about EFTs, are set out in the Electronic Funds
            Transfers Disclosure in the Personal Deposit Agreement.
          </p>
          <BackToTopLink id="pa-17" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-availability-of-services">
          <p className="lead">18. Availability of Services; Business Days</p>
          <p>
            You may access our Website 24 hours a day every day except during Website maintenance, modification, or
            account update periods, when unexpected problems arise such as problems with internet connections, capacity,
            or the like, and when we, in our sole discretion, make all of part of it unavailable. Also, some Online and
            Mobile Banking Services are only available on Business Days. Accordingly, even though you can request at any
            time that we perform a service, we cannot necessarily process it immediately, and sometimes we will wait
            until the appropriate Business Day to act upon some of your requests. All requests that, for any reason, are
            not completed on that Business Day will be completed on the next Business Day, unless some other date has
            been designated by a prior arrangement with you.
          </p>
          <BackToTopLink id="pa-18" url="#table-of-contents-personal-account" />
        </div>
        <div id="pa-general-provisions">
          <p className="lead">19. General Provisions</p>
          <ol>
            <li id="pa-general-provisions-amendments-to-this-agreement">
              <strong>
                Amendments to this Agreement
                <br />
                Except as otherwise required by law, we may in our sole discretion change the terms of this Agreement
                from time to time and at any time. This may include adding new or different terms to, or removing terms
                from, this Agreement.
              </strong>
              <p>
                The Bank reserves the right to change the terms and conditions of this Agreement at any time. We shall
                update this Agreement on the Website and we may notify you of such changes by mail or electronic message
                to your most recent addresses listed on our records. Within the time frames required by the effective
                date of the notice, we may also notify you (1) upon login to the Online and Mobile Service or (2) by
                providing notice on our Website if permitted by applicable law. The notice will be posted or sent at
                least 30days in advance of the effective date of any additional fees, any stricter limits on the type,
                amount or frequency of transactions or any increase in your responsibility for unauthorized
                transactions, unless an immediate change is necessary to maintain the security of the system. By using
                the Online and Mobile Service when such changes become effective, you agree to be bound by the revised
                terms and conditions contained in this Agreement. You can decline the changes by discontinuing use of
                the Online and Mobile Service and notifying us of your election by phone at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-07">
                  800-324-9375
                </a>
                , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by
                visiting your local branch. Changes to fees or terms applicable to Eligible Accounts are governed by the
                agreement otherwise governing the applicable account. Except as otherwise required by law, any change to
                this Agreement applies only to transactions that occur, or claims that arise, after the amendment
                becomes effective.
              </p>
            </li>
            <li id="pa-general-provisions-termination-to-this-agreement">
              <strong>Termination of this Agreement</strong>
              <p>
                This Agreement will be in effect from the earlier of the date of your enrollment in the Online and
                Mobile Service (1) at a branch, or (2) our acceptance of your enrollment submission and will be in
                effect at all times while you are using the Online and Mobile Service or any Online Financial Service.
                Unless otherwise required by applicable law, either you or we may terminate this Agreement and/or your
                access to any Eligible Account or Online Financial Service through the Online and Mobile Service, in
                whole or in part, at any time without notice. The termination of this Agreement will not terminate your
                obligations or our rights arising under this Agreement before such termination.
              </p>
              <p>
                Access to an Eligible Account or Online Financial Service through the Online and Mobile Service, in
                whole or in part, may be suspended and/or reinstated by us, at our discretion, at any time. If
                reinstated, the then current terms of this Agreement will control. You may request reinstatement of an
                Eligible Account or Online Financial Service by calling WaFd Bank Customer Service at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-08">
                  800-324-9375
                </a>{" "}
                or by visiting your local branch.
              </p>
              <p>
                If you wish to cancel any of your Online Financial Services, contact WaFd Bank Customer Service at
                <a href="tel:800-324-9375" id="c3-tel-link-09">
                  800-324-9375
                </a>
                , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by
                visiting your local branch
              </p>
              <p>
                All applicable provisions of this Agreement will survive termination by either you or us, including,
                without limitation, provisions related to intellectual property, warranty disclaimers, limitations of
                liability, indemnification, and the miscellaneous provisions.
              </p>
            </li>
            <li id="pa-general-provisions-governing-law-and-attorney-fees-">
              <strong> Governing Law and Attorney Fees</strong>
              <p>
                Each of your Eligible Accounts and Online Financial Services will be governed by the laws described in
                the agreements you have with us regarding those Eligible Accounts or Online Financial Services (for
                example, your Personal Deposit Agreement ). This Online and Mobile Banking Service Agreement is governed
                by the laws of the state where your account is located, and by federal law. In any legal action or claim
                arising out of or relating to this Agreement, the prevailing party will be entitled to recover costs and
                reasonable attorney fees.
              </p>
            </li>
            <li id="pa-general-provisions-proprietary-rights">
              <strong>Proprietary Rights</strong>
              <p>
                Other than your personal account information, all content included or available on the Website, such as
                advertisements, text graphics, logos, button icons, images, audio clips, and software, is the property
                of WaFd Bank, and/or third parties and is protected by copyrights, trademarks, or other intellectual and
                proprietary rights. The compilation (meaning the collection, arrangement, and assembly) of all content
                on the Website is the exclusive property of WaFd Bank and/or its licensors and is protected by copyright
                or other intellectual property rights.
              </p>
              <p>
                The trademarks, logos, and service marks displayed on the Website (collectively the "Trademarks") are
                the registered and unregistered trademarks of WaFd Bank, or third parties. Under no circumstances may
                you use copy, alter, modify, or change these Trademarks. Nothing contained on the Website should be
                construed as granting by implication or otherwise any license or right to use any Trademark without our
                express written permission, or the express written permission of the third party that has rights to such
                Trademarks, as appropriate.
              </p>
            </li>
            <li id="pa-general-provisions-entire-agreement">
              <strong>Entire Agreement</strong>
              <p>
                In combination with other Account Agreements, this Agreement represents the agreement between you and us
                regarding the Online and Mobile Banking Service and merges and supersedes all previous and
                contemporaneous written or oral agreements and understandings regarding the Online and Mobile Service.
                Each of the rules, terms, and conditions set forth in this Agreement stand alone. Any term or condition
                contained in this Agreement which is inconsistent with the laws governing the Online and Mobile Service
                will be deemed to have been modified by us and applied in a manner consistent with such laws. Except as
                otherwise expressly provided in this Agreement, if any provision of this Agreement is held to be invalid
                or otherwise unenforceable, the remainder of the provisions will remain in full force and effect and
                will in no way be invalidated or otherwise affected.
              </p>
            </li>
            <li id="pa-general-provisions-waiver">
              <strong>Waiver</strong>
              <p>
                Our failure to act with respect to a breach by you or others does not waive our right to act with
                respect to subsequent or similar breaches.
              </p>
            </li>
            <li id="pa-general-provisions-jury-trial-waiver">
              <strong>Jury Trial Waiver</strong>
              <p>
                YOU AND WE EACH AGREE THAT NEITHER YOU NOR WE SHALL (A) SEEK A JURY TRIAL IN ANY LAWSUIT, PROCEEDING,
                COUNTERCLAIM, OR ANY OTHER ACTION BASED UPON, OR ARISING OUT OF, THIS AGREEMENT OR ANY ACCOUNT OR THE
                DEALINGS OF THE RELATIONSHIP BETWEEN YOU OR US, OR (B) SEEK TO CONSOLIDATE ANY SUCH ACTION IN WHICH A
                JURY TRIAL CANNOT BE OR HAS NOT BEEN WAIVED. THE PROVISIONS OF THIS SECTION SHALL BE SUBJECT TO NO
                EXCEPTIONS. NEITHER YOU NOR WE HAVE AGREED WITH OR REPRESENTED TO THE OTHER THAT THE PROVISIONS OF THIS
                SECTION WILL NOT BE FULLY ENFORCED IN ALL INSTANCES. YOU AND WE EACH ACKNOWLEDGE THAT THIS WAIVER HAS
                BEEN KNOWINGLY AND VOLUNTARILY MADE.
              </p>
            </li>
          </ol>
          <BackToTopLink id="pa-19" url="#table-of-contents-personal-account" />
        </div>
        <hr />
        <p className="lead">
          WaFd Bank Online and Mobile Banking Service Agreement for Small Business Account Customers
        </p>
        <p>
          Welcome to Small Business Online and Mobile Banking at WaFd Bank. This Business Online and Mobile Banking
          Service Agreement ("Business Agreement") sets out the terms and conditions for your use of WaFd Bank's Small
          Business Online Banking Service ("Business Online Banking Service") and Small Business Mobile Banking Service
          ("Business Mobile Banking Service") provided to our customers with accounts established primarily for business
          purposes. Please read this Business Agreement carefully. A printer friendly version of this Business Agreement
          is available on our Website. By using the Business Online Banking Service or the Business Mobile Banking
          Service, you agree to the terms and conditions of the Business Agreement as they apply to the service you are
          using, including any instructions we provide on our Website or on your Mobile Device, and any changes in such
          terms and conditions (after their effective date). If you do not agree with these terms and conditions, you
          may not use the Business Online Banking Service or the Business Mobile Banking Service.
        </p>
        <p>
          In this Business Agreement, the words "we", "us", "our", and "Bank" means WaFd Bank, N.A. The words
          "Customer," "you" and "your" mean an individual or entity that is the Owner of a Business Account, an
          Authorized User, and/or a Business Account Signer. Your use of the Business Online Banking Service and the
          Business Mobile Banking Service is also governed by our Business Deposit Account Agreement and Disclosures,
          the Terms and Conditions for use of our Website, the Schedule of Business Fees &amp; Service Charges, and any
          Loan Account agreement (collectively, the "Account Agreements").The Business Account Agreement and
          Disclosures, our Website Terms of Use, and the Schedule of Business Fees &amp; Service charges are available
          on our Website and you may also request a printed copy of any of these documents by visiting your local
          branch.
        </p>
        <p id="table-of-contents-small-business-account">
          <strong>Table of Contents</strong>
        </p>
        <ol className="mb-5">
          <li>
            <Link to="#sba-definitions">
              <strong>Definitions</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-security-online">
              <strong>Security, Password and User Responsibility for Business Online and Mobile Banking </strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-security-online-security">
                  <strong>Security</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-security-online-administrator">
                  <strong>Online Administrator</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-security-online-password">
                  <strong>Password</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-security-online-user-responsibility">
                  <strong>User Responsibility for Business Online and Mobile Banking</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-security-online-procedures">
                  <strong>Security Procedures</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#sba-access-requirements">
              <strong>Access Requirements for Business Online Banking Service</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-available-services">
              <strong>Available Business Online Banking Services</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-mobile-banking-services">
              <strong>Business Mobile Banking Services </strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-mobile-banking-services-sms-terms">
                  <strong>Business SMS Terms and Conditions</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-mobile-banking-services-access-requirements">
                  <strong>Access Requirements for the Business Mobile Banking Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-mobile-banking-services-available-services">
                  <strong>Available Business Mobile Banking Services</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-mobile-banking-services-deposit-services">
                  <strong>Business Mobile Deposit Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-mobile-banking-services-sms-services">
                  <strong>Business SMS (Text) Service</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-mobile-banking-services-push-notifications">
                  <strong>Push Notifications</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#sba-conditions-and-limitations">
              <strong>Conditions and Limitations on Business Online and Mobile Banking Services</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-conditions-and-limitations-accounts">
                  <strong>Savings and Money Market Accounts</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-conditions-and-limitations-internal-transfers">
                  <strong>Internal Transfers</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#sba-fees">
              <strong>Fees</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-placing">
              <strong>Placing Stop Payment Orders</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-statements">
              <strong>Electronic Statements and Notices</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-using-email">
              <strong>Using E-mail to Send Information and Inquiries to Us</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-third-party-services">
              <strong>Third Party Services</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-third-party-services-popmoney">
                  <strong>
                    Popmoney<sup>&reg;</sup> for Small Business
                  </strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-third-party-services-bill-pay">
                  <strong>CheckFree Web Bill Payment Services (Bill Pay)</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#sba-suspension-or-cancellation">
              <strong>Business Online and Mobile Banking Service Suspension or Cancellation by Us</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-privacy">
              <strong>Privacy</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-limitations-on-our-responsibilities">
              <strong>Limitations on Our Responsibilities</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-indemnification">
              <strong>Indemnification</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-notices-and-communication">
              <strong>Notices and Communication</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-notices-and-communication-generally">
                  <strong>Generally</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-notices-and-communication-electronic">
                  <strong>Electronic Delivery and Communication</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-notices-and-communication-returned-information">
                  <strong>Returned, Unclaimed Account-Related Information</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-notices-and-communication-address-changes">
                  <strong>Address Changes</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-notices-and-communication-implementation-of-notices">
                  <strong>Implementation of Notices</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-notices-and-communication-consumer-disclosures">
                  <strong>Consumer Disclosures</strong>
                </Link>
              </li>
            </ol>
          </li>
          <li>
            <Link to="#sba-electronic-banking-transactions">
              <strong>Electronic Banking Transactions</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-availability-of-services">
              <strong>Availability of Services; Business Days</strong>
            </Link>
          </li>
          <li>
            <Link to="#sba-general-provisions">
              <strong>General Provisions</strong>
            </Link>
            <ol className="mt-2">
              <li>
                <Link to="#sba-general-provisions-amendments">
                  <strong>Amendments to this Business Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-termination-of-agreement">
                  <strong>Termination of this Business Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-fees">
                  <strong>Governing Law and Attorney Fees</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-proprietary-rights">
                  <strong>Proprietary Rights</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-entire-agreement">
                  <strong>Entire Agreement</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-waiver">
                  <strong>Waiver</strong>
                </Link>
              </li>
              <li>
                <Link to="#sba-general-provisions-jury-trail-waiver">
                  <strong>Jury Trial Waiver</strong>
                </Link>
              </li>
            </ol>
          </li>
        </ol>

        <div id="sba-definitions">
          <p className="lead">1. Definitions</p>
          <p>
            "Authorized User" means an individual who is authorized by the Eligible Account Owner to view account
            information and transact banking through the Business Online and Mobile Service.
          </p>
          <p>"Business Day" means Monday through Friday, excluding federal banking holidays.</p>
          <p>
            "Computer" means a personal computer (PC) or other desktop Internet-enabled system, including a laptop
            personal computer and excludes a Mobile Device.
          </p>
          <p>
            "Business Account Agreement" means the Business Deposit Account Agreement and Disclosures, which are
            available on our Website and in printed form upon request at your local branch.
          </p>
          <p>
            "Business Account Signer" means an individual who is authorized as a signer on an Eligible Account on a
            signature card or other Bank document.
          </p>
          <p>
            "Business Fee Schedule" means the Schedule of Business Fees &amp; Service Charges, which is available on our
            Website and in printed form upon request at your local branch.
          </p>
          <p>
            "Business Mobile Banking Service" means any activity via our Website and any published WaFd Bank application
            using the Mobile Device you have registered with us.
          </p>
          <p>
            "Business Online Banking Service" means any activity on our Website using a personal computer or other
            internet-enabled system, but excluding any service accessed through a Mobile Device.
          </p>
          <p>
            "Business Online and Mobile Service" means the Business Online Banking Service and Business Mobile Banking
            Service described in this Business Agreement.
          </p>
          <p>
            "Business Online Financial Services" means each of the products and services you may access or enroll in
            through the Business Online and Mobile Service, including services offered by third parties.
          </p>
          <p>
            "Deposit Account" means a checking, savings, NOW, money market, or certificate of deposit account
            established with us primarily for business purposes.
          </p>
          <p>"Eligible" means an account is accessible through the Business Online and Mobile Service.</p>
          <p>
            "Eligible Accounts" means Deposit Accounts and Loan Accounts are that are accessible through the Business
            Online and Mobile Service.
          </p>
          <p>
            "Loan Account" means a loan account you may have with us that was established with us primarily for business
            purposes.
          </p>
          <p>
            "Mobile Device" means a supported mobile device including a mobile phone or other mobile device that is
            web-enabled or capable of sending and receiving text messages.
          </p>
          <p>
            "Online Administrator" means a Business Account Signer who has been authorized by the Owner to manage the
            access of Authorized Users to the Business Online and Mobile Banking Service.
          </p>
          <p>"Owner" means each person who is an owner of an Eligible Account as indicated in our records.</p>
          <p>"SMS" means Short Message Service, which is the text message service of your Mobile Device.</p>
          <p>"Transaction Processing Day" means a Business Day or any other day that Bank processes transactions.</p>
          <p>
            "Website" means WaFd Bank's Business Online Banking Service website and other websites and web pages
            maintained by us and accessible through www.wafdbank.com.
          </p>
          <BackToTopLink id="sba-01" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-security-online">
          <p className="lead">2. Security, Password and User Responsibility for Business Online and Mobile Banking</p>
          <ol>
            <li id="sba-security-online-security">
              <strong>Security</strong>
              <p>
                You can use your user name and password, along with a Computer or Mobile Device, to perform certain
                transactions described below. For security, the Business Online and Mobile Service will not allow access
                if anyone attempts to use a wrong password 5 times or more consecutively. We use additional log-in
                security that incorporates multiple factors to authenticate you as a customer. Additional information
                regarding each Business Online and Mobile Service user's access pattern will be collected and validated
                automatically. A unique electronic signature will be created for each user based on the user's specific
                access pattern; additional authentication may occur automatically when there is a deviation from this
                unique signature.
              </p>
              You agree that we may place session cookies and other identifiers on the Computers, Mobile Devices and
              other devices you use to access the Business Online and Mobile Services. Session cookies help us confirm
              your identity and help us keep your transactions secure when you use the Business Online and Mobile
              Service. We also use cookies for various purposes such as maintaining continuity during an online session,
              and optimizing the performance of our Website.
            </li>
            <li id="sba-security-online-administrator">
              <strong>Online Administrator</strong>
              <br />
              Only a Business Account Signer may enroll as the Online Administrator. When enrolling as the Online
              Administrator, the Business Account Signer will establish a user name and password. The Online
              Administrator may enroll Authorized Users with user names and passwords for using the Business Online and
              Mobile Service.
            </li>
            <li id="sba-security-online-password">
              <strong>Password</strong>
              <br />
              Your password can be changed within the Business Online and Mobile Service. Your password must meet the
              standards we establish from time to time. These standards will be available for your reference whenever
              you create or change a password. We may require you to change or update your password at any time.
            </li>
            <li id="sba-security-online-user-responsibility">
              <strong>User Responsibility for Business Online and Mobile Banking</strong>
              <br />
              You should never provide your user name or password information to a third party. If you do so, you are
              authorizing that party to conduct transactions on your Eligible Accounts. You are also responsible for
              keeping your username and password confidential and for ensuring that you have logged out of the Business
              Online Banking Service or Business Mobile Banking Service when your session is finished. Therefore, you
              are responsible for any transactions, including transfers from or between your Eligible Accounts,
              resulting from you furnishing your user name, password and enhanced log-in security information to a third
              party. The Bank will not be liable and will not reimburse you for any losses that may occur as a result of
              this <em>authorized</em> use of your user name, password and enhanced log-in security.
            </li>
          </ol>
          <p>If you believe that:</p>
          <p>
            or if you suspect any fraudulent activity on your accounts, you agree to immediately notify WaFd Bank
            Customer Service by:
          </p>
          <p>
            Please refer to the Business Account Agreement for additional information regarding your obligation to
            notify us and your liability for unauthorized transactions on your account.
          </p>
          <ol start={5}>
            <li id="sba-security-online-procedures">
              <strong>Security Procedures</strong>
              <br />
              You agree that the use of passwords and other techniques established by the Bank for use of the Business
              Online and Mobile Banking Service constitute a commercially reasonable security procedure for you.
            </li>
          </ol>
          <BackToTopLink id="sba-02" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-access-requirements">
          <p className="lead">3. Access Requirements for Business Online Banking Service</p>
          <p>
            In order to use the Bank's Business Online Banking Service, you will need a Computer, and any software and
            required equipment that can support the list of approved web browsers. The Website is best viewed using the
            most current version of Internet Explorer, Chrome, or Firefox. If you choose to use another browser to
            access the Website, we assume no responsibility for the resulting layout or content. You will also need a
            functioning email address. You are responsible for the installation, maintenance and operation of your
            Computer and all related charges. You are responsible for installing and maintaining appropriate virus
            protection on your Computer. We are not responsible for any errors or failures caused by any malfunction of
            your Computer, or your use of the Business Online Banking Service. We are not responsible for any viruses,
            spyware, malware, worms or related problems that may be associated with your Computer. We are also not
            responsible for any losses or delays in transmission of information that you provide to us or otherwise
            arising out of or incurred in connection with the use of any internet or other service provider providing
            your connection to the internet or any browser software. You are also responsible for all telephone, mobile
            remote access and other similar charges incurred in connecting to the Business Online Banking Service and
            for charges by any internet service providing connection to the internet.
          </p>
          <BackToTopLink id="sba-03" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-available-services">
          <p className="lead">4. Available Business Online Banking Services</p>
          <p>Business Online Banking Services allow you to perform the following actions:</p>
          <ul>
            <li>Review a summary of your Eligible Deposit and Loan Accounts.</li>
            <li>
              Check the activity of your Eligible Deposit and Loan Accounts, including determining which checks have
              cleared and viewing images of cleared checks, for a period of 13 months.
            </li>
            <li>Advance funds from Eligible Loan Accounts to Eligible Deposit Accounts.</li>
            <li>Submit payments to Eligible Loan Accounts.</li>
            <li>Transfer funds between Eligible Deposit Accounts.</li>
            <li>
              Transfer funds to other persons (Popmoney<sup>&reg;</sup> for Small Business).
            </li>
            <li>
              Send invoices to vendors and customers (Popmoney<sup>&reg;</sup> for Small Business).
            </li>
            <li>Change passwords, login name, or e-mail addresses.</li>
            <li>Pay bills using CheckFree Bill Payment Services.</li>
            <li>Stop payment on checks that have not been paid.</li>
            <li>View 18 months of electronic statements if you have enrolled in E-statements.</li>
            <li>Send and receive electronic mail to and from us.</li>
          </ul>
          <p>
            Popmoney<sup>&reg;</sup> for Small Business and CheckFree Bill Payment are described in the section entitled
            Third Party Services. Not all services specified above are available for certificate of deposit (CD) Deposit
            Accounts.
          </p>
          <p>
            We may periodically make available new internet services or products. You agree that if you use or acquire
            any such services or products after they become available, you will be bound by all applicable terms, as
            well as by this Business Agreement.
          </p>
          <p>Not all services specified above are available through Business Mobile Banking Services.</p>
          <BackToTopLink id="sba-04" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-mobile-banking-services">
          <p className="lead">5. Business Mobile Banking Services</p>{" "}
          <p>
            WaFd Bank offers banking services on your Mobile Device through Mobiliti. Our Business Mobile Banking
            Service is an Internet-based platform you access via a URL from your Mobile Device's browser or by
            downloading an App from either the Apple App Store or Google Play. Internet access from your Mobile Device
            is required to enroll in some Business Mobile Banking Services.
          </p>
          <ol>
            <li id="sba-mobile-banking-services-sms-terms">
              <strong>Business SMS Terms and Conditions</strong>
              <p>
                <strong>Message Frequency Based on Account Settings</strong>. For help, text "HELP" to 66273. To cancel,
                text "STOP" to 66273 at any time. In case of questions please contact customer service at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-10">
                  800-324-9375
                </a>{" "}
                or visit our Website, www.wafdbank.com.
              </p>
              <p>
                <strong>Program</strong>. We offer our customers mobile access to their Bank account information (e.g.,
                for checking balances and last transactions) over SMS, as well as the option to set up alerts for their
                accounts (e.g., low balance alerts). Enrollment requires identification of the customer's banking
                relationship as well as providing a mobile phone number. The mobile phone number's verification is done
                by the customer receiving an SMS message with a verification code which the user will have to enter on
                the Website. Additionally, customers may select the type of alerts and other preferences which will
                determine, together with their account data, the frequency of alerts delivered to the customer. The SMS
                program continues until a customer opts out. Your mobile carrier's text message, data rates, and mobile
                phone usage charges may apply. Customers may opt out of this SMS program at any time.
              </p>
              <p>
                Questions: You can contact us at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-11">
                  800-324-9375
                </a>
                , or send a text message with the word "HELP" to this number: 66273. We can answer any questions you
                have about the SMS program.
              </p>
              <p>
                <strong>To Stop the SMS Program</strong>. To stop the messages from coming to your phone, you can opt
                out of the SMS program via SMS. Just send a text that says "STOP" to this number: 66273. You'll receive
                a one-time opt-out confirmation text message. After that, you will not receive any future messages.
              </p>
              <p>
                <strong>Terms and Conditions</strong>. By participating in Business Mobile Banking Services, you are
                agreeing to these SMS Terms and Conditions.
              </p>
              Our participating carriers include (but are not limited to): AT&amp;T, SprintPCS, T-Mobile<sup>&reg;</sup>
              , U.S. Cellular<sup>&reg;</sup>, Verizon Wireless, and MetroPCS.
            </li>
            <li id="sba-mobile-banking-services-access-requirements">
              <strong>
                Access Requirements for the Business Mobile Banking Service
                <br />
              </strong>
              To register a Mobile Device for any of our Business Mobile Banking Services, you must be an authorized
              user for the Mobile Device registered and you agree to provide us with true, accurate, current and
              complete information during the enrollment and registration process. You are responsible for the
              installation, maintenance and operation of your Mobile Device. You are responsible for installing and
              maintaining appropriate virus protection on your Mobile Device. We are not responsible for any errors or
              failures caused by any malfunction of your Mobile Device, or your use of the Business Mobile Banking
              Service. We are not responsible for any viruses, spyware, malware, worms or related problems that may be
              associated with your Mobile Device. We are also not responsible for any losses or delays in transmission
              of information that you provide to us or otherwise arising out of or incurred in connection with the use
              of any internet or other service provider providing your connection to the internet or any browser
              software. You are responsible for any fees or other charges that your wireless carrier may charge for any
              related data or message services, including without limitation, for SMS (short message service).
            </li>
            <li id="sba-mobile-banking-services-available-services">
              <strong>
                Available Business Mobile Banking Services
                <br />
              </strong>
              Business Mobile Banking Services allow you to perform the following actions:
            </li>
          </ol>
          <p>
            Not all services specified above are available for certificate of deposit (CD) Deposit Accounts. Popmoney
            <sup>&reg;</sup>
            for Small Business is not currently available using the Business Mobile Banking Service. Not all services
            available in the Business Mobile Banking Service are available in our Business Online Banking Service.
          </p>
          <ol start={4}>
            <li id="sba-mobile-banking-services-deposit-services">
              <strong>
                Business Mobile Deposit Service
                <br />
              </strong>
              The Business Mobile Deposit Service is a remote deposit capture service (referred to as a "Business Mobile
              Deposit Service") that allows you to use a camera-enabled Mobile Device to deposit certain checks
              ("Eligible Checks" as described below) to your Eligible Accounts by scanning a check and electronically
              delivering the digital image of the check and associated deposit information to us or our designated
              processor.
            </li>
          </ol>
          <p>
            Eligible Checks. "Eligible Checks" means checks as defined by Regulation CC and does not include Ineligible
            Items (defined below).
          </p>
          <p>
            Ineligible Items. You may not use the service to deposit any Ineligible Items. "Ineligible Items" include
            any item that: (a) is payable to "Cash" or any indefinite payee or to a person other than you, even if
            endorsed to you; (b) lacks the original signature of the drawer; (c) was created from an electronic image;
            (d) is irregular in any way, as determined by us in our sole discretion; (e) was returned unpaid; (f) is not
            dated or is postdated, or is more than six months old; (g) was drawn on a foreign bank or any entity that is
            not a bank, or is payable in a foreign currency; (h) is a traveler's check or registered government warrant
            or bond; or (i) is not a check under Regulation CC.
          </p>
          <p>
            Image Quality. The image of an item transmitted to us using the Business Mobile Deposit Service must be
            legible. The image quality of the check or item must comply with the requirements established from time to
            time by the American National Standard Institute, the Board of Governors of the Federal Reserve Board, or
            any other regulatory agency, clearing house or association.
          </p>
          <p>
            Endorsement and Collection. You agree to endorse properly each check you deposit using the Business Mobile
            Deposit Service and to conspicuously and indelibly mark "For Deposit Only at WaFd Bank" beneath your
            endorsement. The manner in which the checks are cleared, presented for payment, and collected is in our sole
            discretion.
          </p>
          <p>
            Availability of Funds; Right to Reject. We reserve the right to place a hold on or to reject any item(s)
            deposited through the Business Mobile Deposit Service. Please see the Business Deposit Agreement for
            information regarding availability of funds.
          </p>
          <p>
            Storage and Disposal of Original Checks and Items; Retention of Copies.
            <br />
            Checks Submitted to Us. With respect to any original check for which a check image has been transmitted to
            us via the Business Mobile Deposit Service, you agree to safely store the check in such a manner that others
            cannot gain access to it until such time as you receive confirmation that your check image has been accepted
            for processing.
          </p>
          <p>
            Checks Accepted for Deposit. You will receive an electronic confirmation that the check image you
            transmitted to us via the Business Mobile Deposit Service has been accepted for deposit (or alternatively,
            an adjustment notice setting forth the reason it could not be deposited through the Business Mobile Deposit
            Service).
          </p>
          <p>
            Disposal of Transmitted Checks. You agree to safeguard and keep the original check for 14 Business Days
            after you have transmitted the check. After that period, once you have verified that the check has been
            credited to your Eligible Account, you agree to indelibly mark the check as "VOID" or to dispose of it in a
            manner that will prevent anyone from presenting it for deposit. If we determine that we require the original
            paper check, you will promptly provide any check you have not destroyed to us to aid us in the clearing and
            collection process, to resolve claims by third parties with respect to any Item, or for our audit purposes.
          </p>
          <p>
            Deposit Limits. We have no obligation to accept for deposit any check you transmit using the Business Mobile
            Deposit Service: (a) if more than one check is included in the transmission; (b) if you have already
            transmitted five checks using the Business Mobile Deposit Service on that Business Day; (c) that is in an
            amount greater than $5,000; (d) if the amount of the check would, when added to the amount of checks you
            already transmitted for deposit as of that Business Day, would exceed $10,000 or (e) if deposit of that
            check would cause the sum of the amounts of the checks you transmitted for deposit using the Business Mobile
            Deposit Service within the previous 30 days period to exceed $50,000. We reserve the right to modify these
            limits at any time.
          </p>
          <p>
            You may deposit checks using your Mobile Device Monday through Friday. Accepted deposits made prior to 3 PM
            Pacific Time on a Business Day will be credited by 6 PM Pacific Time the same Business Day. Accepted
            deposits made after 3 PM Pacific Time or on a day that is not a Business Day will be credited the following
            Business Day.
          </p>
          <ol start={5}>
            <li id="sba-mobile-banking-services-sms-services">
              <strong>
                Business SMS (Text) Service
                <br />
              </strong>
              The SMS (Text) service allows you to access available information regarding your Eligible Accounts via SMS
              text messaging from your Mobile Device.
            </li>
            <li id="sba-mobile-banking-services-push-notifications">
              <strong>
                Push Notifications
                <br />
              </strong>
              Push notifications allow you to receive "push" versions of some Business Mobile Banking Service alerts on
              Apple iOS or Google Android Mobile Devices with the WaFd Bank mobile banking application installed. iOS
              notifications can appear as pop up messages or banners and Android notifications appear as a banner. If
              you initiate this service, you can turn off Push notifications within the WaFd Bank mobile banking
              application or in the settings of your Mobile Device.
            </li>
          </ol>
          <BackToTopLink id="sba-05" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-conditions-and-limitations">
          <p className="lead">6. Conditions and Limitations of Business Online and Mobile Banking Services</p>
          <ol>
            <li id="sba-conditions-and-limitations-accounts">
              <strong>
                Savings and Money Market Accounts
                <br />
              </strong>
              Your ability to transfer funds between and/or make payments from certain Deposit Accounts is limited by
              law. For any Statement Savings Account(s) (including Money Market Accounts), you may make as many
              in-person withdrawals at a teller window or any ATM as you wish. However, federal regulations permit the
              depositor to make no more than a combined total of 6 pre-authorized, automatic, electronic (including
              computer initiated), telephone withdrawals or transfers, or by check, draft, debit card, or similar order
              payable to third parties in any monthly period. We may impose a fee, as disclosed on the{" "}
              <em>Business Fee Schedule</em>, for each such withdrawal in excess of 6 that you make in any monthly
              period. These fees will be reflected in your monthly statement. In addition, repeated violations will
              result in the Deposit Account being closed or changed from a savings type account to a transaction
              account.
            </li>
            <li id="sba-conditions-and-limitations-internal-transfers">
              <strong>
                Internal Transfers
                <br />
              </strong>
              Funds can be transferred the same day or on a future date between your Eligible Accounts. Future-dated and
              recurring transfers are processed each calendar day between 9 PM and 11 PM Pacific Time. Same day
              transfers between Eligible Accounts are processed when received. If the transfer request is submitted
              prior to 11 PM Pacific Time it will be dated the same day. Transfer requests submitted after 11 PM Pacific
              Time will be dated the next Transaction Processing Day.
            </li>
          </ol>
          <BackToTopLink id="sba-06" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-fees">
          <p className="lead">7. Fees</p>
          <p>
            All account fees and charges apply to actions requested or performed through the Business Online and Mobile
            Banking Services. (See the <em>Business Fee Schedule</em> for a full listing of fees)We reserve the right to
            charge fees for any service provided through the Business Online and Mobile Service after we notify you or
            amend this Business Agreement. You agree that any fees may be deducted automatically from your account (or
            any other account you have with us if there are insufficient funds in that account).
          </p>
          <BackToTopLink id="sba-07" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-placing">
          <p className="lead">8. Placing Stop Payment Orders</p>
          <p>
            You can place stop payment orders using our Online Stop Payment service. To place a stop payment on a check
            or a series of checks, sign on to the Business Online Banking Service and select the "Accounts" menu, then
            select "Stop Payment". All information requested must be provided before we can process a stop payment. The
            terms and conditions governing stop payments are provided in our Business Account Agreement. If you send us
            an online stop payment order, we will send you a stop payment confirmation form. If you wish to cancel the
            stop payment or change any information, follow the directions on the confirmation form. There will be a fee
            for placing a stop payment (see the <em>Business Fee Schedule</em> for the current fee).
          </p>
          <BackToTopLink id="sba-08" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-statements">
          <p className="lead">9. Electronic Statements and Notices</p>
          <p>
            If you enroll to receive eStatements or disclosures, documentation, and other communications concerning your
            Eligible Accounts ("Notices") electronically, statements or Notices, as applicable, for your Eligible
            Accounts will be available only through the Business Online Banking Service. Your consent to receive
            eStatements covers the account statement for the Eligible Accounts you designate within the Business Online
            Banking Service. Your consent to receive Notices electronically covers any disclosures, agreements,
            documentation, and other communications from the Bank for the Eligible Accounts you designate within the
            Business Online Banking Service. The Bank will discontinue mailing such account statements and may
            discontinue mailing Notices to your address of record. You agree to be bound by any and all laws, rules,
            regulations and official issuances applicable to eStatements now existing or which may hereafter be enacted,
            issued or enforced.
          </p>
          <p>
            We will send you notice via e-mail when a new document is available for review. Notice will be sent shortly
            after the end of your statement period and will provide instructions on how to access your eStatement or
            Notice. This is the only notice you receive of the availability of your eStatement or Notice online. We will
            archive electronic statements and Notices for your Eligible Accounts for a rolling 18 months, regardless of
            when you enroll.
          </p>
          <p>
            Your receipt of e-Statements or Notices does not in any way alter your obligation to promptly review your
            transaction history. You must promptly access/review your e-Statement or Notices and any accompanying items
            and notify us immediately of any error, unauthorized transaction, or any other irregularity. The error
            resolution provisions in the Business Account Agreement set out the timelines for reporting errors contained
            on your account statement.
          </p>
          <p>
            At any time, you may request a paper copy of your account statement or any disclosure. Paper copies may be
            acquired by contacting us by phone at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-12">
              800-324-9375
            </a>
            , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, by requesting
            through the Business Online Banking Service (for account statements only), or by visiting your local branch.
            Fees may apply to requests for paper copies of your account statement (see the{" "}
            <em>Business Fee Schedule</em>). There is no fee for printed copies of disclosures.
          </p>
          <BackToTopLink id="sba-09" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-using-email">
          <p className="lead">10. Using Email to Send Information and Inquiries to Us</p>{" "}
          <p>
            Normal internet e-mail transmissions may not be secure. The Business Online Banking Service provides secure
            messaging ("Secure Message") for you to ask questions about your accounts or give comments on the Business
            Online Banking Service. Secure Message provides the ability to securely communicate sensitive or
            confidential information. Secure Message is accessible after you sign on with your password to a secure
            session of the Business Online Banking Service. To ensure the security of your account information, we
            recommend that you use only Secure Message when asking specific questions about your accounts or when
            sharing sensitive or confidential account information about your WaFd Bank accounts. You cannot use email to
            initiate transactions on your accounts. For transactions, please use the appropriate functions within the
            Business Online Banking Service or call WaFd Bank Customer Service at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-13">
              800-324-9375
            </a>{" "}
            or by visiting your local branch.
          </p>
          <BackToTopLink id="sba-10" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-third-party-services">
          <p className="lead">11. Third Party Services</p>{" "}
          <p>
            Through our Website we offer access to the following services provided by third parties. These services are
            conducted on the Website of these third parties and those sites may have separate terms and conditions.
            Between you and us, your use of those services is subject to this Business Agreement.
          </p>
          <ol>
            <li id="sba-third-party-services-popmoney">
              <strong>
                Popmoney<sup>&reg;</sup> for Small Business
                <br />
              </strong>
              We offer person to person payments and electronic invoicing through FiServ Solutions, Inc. These services
              are governed by the Terms and Conditions for Popmoney<sup>&reg;</sup> Transfer Service for Small Business,
              which are available on our Website and on the Popmoney<sup>&reg;</sup> site when you access this service.
              To use this service, you must have an account profile that has a United States address, a unique United
              States phone number, an active unique email address, a Social Security Number or qualifying Tax
              Identification Number (TIN), and applied for and been given access to our Business Online and Mobile
              Service. Your eligible Deposit Account must be in good standing and enabled for ACH transactions and
              Business Online Banking Service transfers. Fees may apply; please review the current Business Fee
              Schedule. FiServ Solutions, Inc., the service provider, may also charge fees that are specified in the
              Terms and Conditions for Popmoney<sup>&reg;</sup> Transfer Service for Small Business. This service is not
              available on the Business Mobile Banking Service.
            </li>
            <li id="sba-third-party-services-bill-pay">
              <strong>
                CheckFree Web Bill Payment Services (Bill Pay)
                <br />
              </strong>
              We offer bill payment services through FiServ CheckFree. Use of Electronic Bill Delivery and Bill Payment
              services ("Bill Payment Services") is governed by the <em>TERMS AND CONDITIONS FOR CHECKFREE WEB</em>,
              which is available on our Website and on the CheckFree site when you access this service. The Bill Payment
              Services allows you to pay bills using our Business Online Banking Service. To be eligible to use the Bill
              Payment Services you must have an account profile that has a United States Address, a unique United States
              phone number, an active unique e-mail address, and applied for and been given access to our Business
              Online and Mobile Service. Using the Bill Payment Services, you can arrange for the payment of current,
              future, and recurring bills from your Eligible Accounts. See the Business Fee Schedule for applicable
              fees. This service is available on the Business Online Banking Service and the Business Mobile Banking
              Service.
            </li>
          </ol>
          <BackToTopLink id="sba-11" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-suspension-or-cancellation">
          <p className="lead">12. Business Online and Mobile Service Suspension or Cancellation by Us</p>
          <p>
            You agree that at any time and without prior notice we may suspend access to your Business Online or Mobile
            Service if we suspect fraudulent activity or misuse of the services or functionality. We may take such
            action to further protect your accounts prior to notice as the speed of such transactions may not allow us
            timely notification to protect your funds or private information.
          </p>
          <BackToTopLink id="sba-12" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-privacy">
          <p className="lead">13. Privacy</p>
          <p>
            We are committed to protecting your privacy. All information gathered from you in connection with using the
            Business Online and Mobile Service will be governed by the provisions of the WaFd Bank Privacy Policy as set
            out in our Privacy Notice and our Online Privacy Policy, available on our Website at www.wafdbank.com.
          </p>
          <BackToTopLink id="sba-13" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-limitations-on-our-responsibilities">
          <p className="lead">14. Limitations on Our Responsibilities</p>{" "}
          <p>
            We agree to make reasonable efforts to ensure the full performance of the Business Online and Mobile
            Service. We will be responsible for acting only on those instructions that are actually received and cannot
            assume responsibility for malfunctions in communications facilities not under our control that may affect
            the accuracy or timeliness of messages you send. We are not responsible for any losses or delays in
            transmission of instructions arising out of the use of any Internet service provider, your connection to the
            Internet, your Computer, your Mobile Device, or caused by any browser software. Our sole responsibility for
            an error in a transfer will be to correct the error, but in no case will we be liable for any indirect,
            special, incidental or consequential damages. In states that do not allow the exclusion or limitation of
            liability for indirect, special, incidental or consequential damages, our liability is limited to the
            maximum extent permitted by applicable law. We are not responsible for any indirect, special, incidental of
            consequential damages arising in any way out of your use of the Business Online and Mobile Service.
          </p>
          <p>
            WE MAKE NO EXPRESS OR IMPLIED WARRANTIES CONCERNING THE BUSINESS ONLINE AND MOBILE BANKING SERVICE,
            INCLUDING BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT OF A THIRD PARTY'S PROPRIETARY RIGHTS UNLESS DISCLAIMING SUCH WARRANTIES IS PROHIBITED BY
            APPLICABLE LAW.
          </p>
          <BackToTopLink id="sba-14" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-indemnification">
          <p className="lead">15. Indemnification</p>{" "}
          <p>
            Except to the extent that we are liable under the terms of this Business Agreement or another agreement
            governing the applicable Eligible Account or Business Online Financial Service, you agree to indemnify,
            defend, and hold us, our affiliates, officers, directors, employees, consultants, agents, service providers,
            and licensors harmless from any and all third-party claims, liability, damages, and/or costs (including but
            not limited to reasonable attorney's fees) arising from:
          </p>
          <ul>
            <li>
              a third-party claim, action, or allegation of infringement, misuse, or misappropriation based on
              information, data, files, or other materials submitted by you to us;
            </li>
            <li>
              any fraud, manipulation, or other breach of this Business Agreement or the Business Online and Mobile
              Service;
            </li>
            <li>your violation of any law or rights of a third party; or</li>
            <li>
              the provision of the Business Online and Mobile Service or use of the Business Online and Mobile Service
              by you or any third party.
            </li>
          </ul>
          <p>
            We reserve the right, at our own expense, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any
            available defenses. You will not settle any action or claims on our behalf without our prior written
            consent. This indemnification is provided without regard to whether our claim for indemnification is due to
            the use of the Business Online and Mobile Service by you or your authorized representative or delegate.
          </p>
          <BackToTopLink id="sba-15" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-notices-and-communication">
          <p className="lead">16. Notices and Communication</p>
          <ol>
            <li id="sba-notices-and-communication-generally">
              <strong>
                Generally
                <br />
              </strong>
              Except as expressly provided otherwise in this Business Agreement, we will provide you notices and other
              information regarding your Eligible Accounts, Business Online Financial Service, or the Business Online
              and Mobile Service (collectively "Account-Related Information") through mail, electronically, or by other
              means available. This information will be sent to the postal or electronic address ("E-Address") of the
              Owner of the applicable Eligible Account or Business Online Financial Service as reflected in our records,
              unless a different postal or electronic address has been specified in accordance with procedures we may
              establish from time to time. The E-Address may be an e-mail address, other Internet address, text
              messaging number, or other electronic access address provided to us in accordance with this Business
              Agreement. Account-Related Information that is held for pick-up or posted on our Website will be deemed to
              be delivered to, and received by, you at the time that we make it available for pick-up, display it to
              you, or send notice in accordance with this Business Agreement that it is posted on our Website, as
              applicable. The person receiving the notice is responsible for providing copies of all Account-Related
              Information to all joint Owners. We reserve the right at all times to communicate all Account-Related
              Information to you through the U.S. Postal Service or overnight courier, at our sole option.
            </li>
            <li id="sba-notices-and-communication-electronic">
              <strong>Electronic Delivery and Communication</strong>
              <p>
                Unless otherwise required by applicable law, if we are required to provide you with information in
                writing, we may, at our option, send it electronically either:
              </p>
              <ul>
                <li>to your E-Address;</li>
                <li>
                  by posting the information on our Website or to your Business Online Banking Service secure mailbox,
                  and sending you a notice to your postal address or E-Address (either separately or as part of an
                  account statement) telling you that the information has been posted and providing instructions on how
                  to view it; or
                </li>
                <li>to the extent permitted by law, by posting the information to our Website.</li>
              </ul>
            </li>
          </ol>
          <p>
            At any time, you may request a paper copy of any communication which has been delivered electronically.
            Paper copies may be acquired by contacting us by phone at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-14">
              800-324-9375
            </a>
            , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by visiting
            your local branch. Fees may apply. There is no fee for printed copies of disclosures.
          </p>
          <p>
            You agree that we may from time to time make telephone calls and send text messages to you in order for us
            to:
          </p>
          <p>
            The ways we may call you include using prerecorded/artificial voice messages and/or through the use of an
            automatic telephone dialing system. The ways we may send text messages to you include through the use of an
            automatic telephone dialing system. We may call you and send text messages to your telephone number,
            including mobile/cellular telephone numbers that could result in charges to you. You may opt out of
            receiving text messages by calling the Client Care Center at{" "}
            <a href="tel:800-324-9375" id="c3-tel-link-15">
              800-324-9375
            </a>
            , visiting your local branch or following the instructions on the text message.
          </p>
          <ol start={3}>
            <li id="sba-notices-and-communication-returned-information">
              <strong>
                Returned, Unclaimed Account-Related Information
                <br />
              </strong>
              Unless otherwise prohibited by the laws governing your Eligible Account or Business Online Financial
              Service, this section applies if two or more separate Account-Related Information documents are returned
              or, in the case of electronic notifications, one or more notices are returned as undeliverable. This means
              we may discontinue sending Account-Related Information or electronic notifications to you until you
              provide a valid postal or E-Address to us.
            </li>
          </ol>
          <blockquote>
            <p>Additionally, we may, at our sole option:</p>
            <ul>
              <li>destroy Account-Related Information that is sent to you and returned to us as undeliverable; or</li>
              <li>
                discontinue sending the Account-Related Information through the delivery channel generating returns and
                utilize an alternative delivery channel.
              </li>
            </ul>
            <p>
              For example, if we are sending your Account-Related Information to you via the U.S. Postal Service, and
              the information is returned to us as undeliverable on two or more occasions, we may attempt to deliver the
              information to your E-Address, instead.
            </p>
          </blockquote>
          <ol start={4}>
            <li id="sba-notices-and-communication-address-changes">
              <strong>Address Changes</strong>
              <p>
                You agree that you will notify us immediately in the event of a change to your address or E-Address.
                Address changes may be initiated at your request. You may instruct us to change the address or E-Address
                to which we send notices or Account-Related Information concerning your Account at any time.
              </p>
              <p>
                You may update your e-mail address information by logging in to your Business Online Banking Service and
                clicking on "Update Profile" in the My Information drop down. Physical address changes must be made
                through your branch or by contacting us by U.S. Mail.
              </p>
              Unless you instruct us otherwise, when you notify us of an address change for a specified account, we may
              change the postal or E-Address, as applicable, for all or some of your other account(s) with us.
            </li>
            <li id="sba-notices-and-communication-implementation-of-notices">
              <strong>
                Implementation of Notices
                <br />
              </strong>
              Except as provided by applicable law, any notice you send us will not be effective until we actually
              receive it and have a reasonable opportunity to act on it. If there is more than one Owner on your
              Eligible Account, we may send Account-Related Information to any one of them. You assume the risk of loss
              in the mail or otherwise in transit. Any notice or Account-Related Information we send you will be
              effective when mailed, sent electronically or otherwise made available to you.
            </li>
            <li id="sba-notices-and-communication-consumer-disclosures">
              <strong>
                Disclosures.
                <br />
              </strong>
              If we are required to make certain disclosures to you in writing, you can agree to receive some or all of
              those disclosures electronically pursuant to electronic communications disclosures that we may make from
              time to time (eDisclosure). We agree to provide reasonable notice of changes in eDisclosures to the extent
              required by applicable law. Unless applicable law specified a longer period, you agree that 15 days will
              be reasonable notice and that we may provide shorter notice if, in our good faith discretion, such is
              advisable to prevent harm or to protect us or you.
            </li>
          </ol>
          <BackToTopLink id="sba-16" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-electronic-banking-transactions">
          <p className="lead">17. Electronic Banking Transactions</p>
          <p>
            Your liability for unauthorized electronic banking transactions and procedures for resolution of errors or
            questions about electronic banking transactions are set out in the Business Account Agreement.
          </p>
          <BackToTopLink id="sba-17" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-availability-of-services">
          <p className="lead">18. Availability of Services; Business Days</p>
          <p>
            You may access our Website 24 hours a day every day except during Website maintenance, modification, or
            account update periods, when unexpected problems arise such as problems with internet connections, capacity,
            or the like, and when we, in our sole discretion, make all of part of it unavailable. Also, some Business
            Online and Mobile Service are only available on Business Days. Accordingly, even though you can request at
            any time that we perform a service, we cannot necessarily process it immediately, and sometimes we will wait
            until the appropriate Business Day to act upon some of your requests. All requests that, for any reason, are
            not completed on that Business Day will be completed on the next Business Day, unless some other date has
            been designated by a prior arrangement with you.
          </p>
          <BackToTopLink id="sba-18" url="#table-of-contents-small-business-account" />
        </div>

        <div id="sba-general-provisions">
          <p className="lead">19. General Provisions</p>
          <ol>
            <li id="sba-general-provisions-amendments">
              <strong>
                Amendments to this Business Agreement
                <br />
              </strong>
              <strong>
                Except as otherwise required by law, we may in our sole discretion change the terms of this Business
                Agreement from time to time and at any time. This may include adding new or different terms to, or
                removing terms from, this Business Agreement.{" "}
              </strong>
              The Bank reserves the right to change the terms and conditions of this Business Agreement at any time. We
              shall update this Business Agreement on the Website and we may notify you of such changes by mail or
              electronic message to your most recent addresses listed on our records. The notice will be posted or sent
              at least 30 days in advance of the effective date of any additional fees, any stricter limits on the type,
              amount or frequency of transactions or any increase in your responsibility for unauthorized transactions,
              unless an immediate change is necessary to maintain the security of the system. By using the Business
              Online and Mobile Service when such changes become effective, you agree to be bound by the revised terms
              and conditions contained in this Business Agreement. You can decline the changes by discontinuing use of
              the Business Online and Mobile Service and notifying us of your election by phone at{" "}
              <a href="tel:800-324-9375" id="c3-tel-link-16">
                800-324-9375
              </a>
              , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by
              visiting your local branch. Changes to fees or terms applicable to Eligible Accounts are governed by the
              agreement otherwise governing the applicable account. Except as otherwise required by law, any change to
              this Business Agreement applies only to transactions that occur, or claims that arise, after the amendment
              becomes effective.
            </li>
            <li id="sba-general-provisions-termination-of-agreement">
              <strong>Termination of this Business Agreement</strong>
              <p>
                This Business Agreement will be in effect from the earlier of the date of your enrollment in the
                Business Online and Mobile Service (1) at a branch, or (2) our acceptance of your enrollment submission
                and will be in effect at all times while you are using the Business Online and Mobile Service or any
                Business Online Financial Service. Unless otherwise required by applicable law, either you or we may
                terminate this Business Agreement and/or your access to any Eligible Account or Business Online
                Financial Service through the Business Online and Mobile Service, in whole or in part, at any time
                without notice. The termination of this Business Agreement will not terminate your obligations or our
                rights arising under this Business Agreement before such termination.
              </p>
              <p>
                Access to an Eligible Account or Business Online Financial Service through the Business Online and
                Mobile Service, in whole or in part, may be suspended and/or reinstated by us, at our discretion, at any
                time. If reinstated, the then current terms of this Business Agreement will control. You may request
                reinstatement of an Eligible Account or Business Online Financial Service by calling WaFd Bank Customer
                Service at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-17">
                  800-324-9375
                </a>{" "}
                or by visiting your local branch.
              </p>
              <p>
                If you wish to cancel any of your Business Online Financial Services, contact WaFd Bank Customer Service
                at{" "}
                <a href="tel:800-324-9375" id="c3-tel-link-18">
                  800-324-9375
                </a>
                , by mail at WaFd Bank, Attn: Client Care Center, 425 Pike Street, Seattle, Washington 98101, or by
                visiting your local branch
              </p>
              All applicable provisions of this Business Agreement will survive termination by either you or us,
              including, without limitation, provisions related to intellectual property, warranty disclaimers,
              limitations of liability, indemnification, and the miscellaneous provisions.
            </li>
            <li id="sba-general-provisions-fees">
              <strong> Governing Law and Attorney Fees</strong>
              <br />
              Each of your Eligible Accounts and Business Online Financial Services will be governed by the laws
              described in the agreements you have with us regarding those Eligible Accounts or Business Online
              Financial Services (for example, your Business Account Agreement). This Business Agreement is governed by
              the laws of the state where your account is located and by federal law. In any legal action or claim
              arising out of or relating to this Business Agreement, the prevailing party will be entitled to recover
              costs and reasonable attorney fees. Subject to applicable law, you agree that any action in relation to an
              alleged breach of this Business Agreement shall be commenced within 1 year of the date of the breach,
              without regard to the date the breach is discovered.
            </li>
            <li id="sba-general-provisions-proprietary-rights">
              <strong> Proprietary Rights</strong>
              <p>
                Other than your personal account information, all content included or available on the Website, such as
                advertisements, text graphics, logos, button icons, images, audio clips, and software, is the property
                of WaFd Bank, and/or third parties and is protected by copyrights, trademarks, or other intellectual and
                proprietary rights. The compilation (meaning the collection, arrangement, and assembly) of all content
                on the Website is the exclusive property of WaFd Bank and/or its licensors and is protected by copyright
                or other intellectual property rights.
              </p>
              The trademarks, logos, and service marks displayed on the Website (collectively the "Trademarks") are the
              registered and unregistered trademarks of WaFd Bank, or third parties. Under no circumstances may you use
              copy, alter, modify, or change these Trademarks. Nothing contained on the Website should be construed as
              granting by implication or otherwise any license or right to use any Trademark without our express written
              permission, or the express written permission of the third party that has rights to such Trademarks, as
              appropriate.
            </li>
            <li id="sba-general-provisions-entire-agreement">
              <strong> Entire Agreement</strong>
              <br />
              In combination with other Account Agreements, this Business Agreement represents the agreement between you
              and us regarding the Business Online and Mobile Service and merges and supersedes all previous and
              contemporaneous written or oral agreements and understandings regarding the Business Online and Mobile
              Service. Each of the rules, terms, and conditions set forth in this Business Agreement stand alone. Any
              term or condition contained in this Business Agreement which is inconsistent with the laws governing the
              Business Online and Mobile Service will be deemed to have been modified by us and applied in a manner
              consistent with such laws. Except as otherwise expressly provided in this Business Agreement, if any
              provision of this Business Agreement is held to be invalid or otherwise unenforceable, the remainder of
              the provisions will remain in full force and effect and will in no way be invalidated or otherwise
              affected.
            </li>
            <li id="sba-general-provisions-waiver">
              <strong> Waiver</strong>
              <br />
              Our failure to act with respect to a breach by you or others does not waive our right to act with respect
              to subsequent or similar breaches.
            </li>
            <li id="sba-general-provisions-jury-trail-waiver">
              <strong>Jury Trial Waiver</strong>
              <br />
              YOU AND WE EACH AGREE THAT NEITHER YOU NOR WE SHALL (A) SEEK A JURY TRIAL IN ANY LAWSUIT, PROCEEDING,
              COUNTERCLAIM, OR ANY OTHER ACTION BASED UPON, OR ARISING OUT OF, THIS AGREEMENT OR ANY ACCOUNT OR THE
              DEALINGS OF THE RELATIONSHIP BETWEEN YOU OR US, OR (B) SEEK TO CONSOLIDATE ANY SUCH ACTION IN WHICH A JURY
              TRIAL CANNOT BE OR HAS NOT BEEN WAIVED. THE PROVISIONS OF THIS SECTION SHALL BE SUBJECT TO NO EXCEPTIONS.
              NEITHER YOU NOR WE HAVE AGREED WITH OR REPRESENTED TO THE OTHER THAT THE PROVISIONS OF THIS SECTION WILL
              NOT BE FULLY ENFORCED IN ALL INSTANCES. YOU AND WE EACH ACKNOWLEDGE THAT THIS WAIVER HAS BEEN KNOWINGLY
              AND VOLUNTARILY MADE.
            </li>
          </ol>
          <BackToTopLink id="sba-19" url="#table-of-contents-small-business-account" />
        </div>
      </section>
    </SimpleLanding>
  );
};

export default BusinessBankingAccountAgreement;
